import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import { Heading, Flex, Skeleton, Text,Card, Button, Box, Message, ChevronDownIcon, SubMenu, SubMenuItem} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault } from 'state/poolsDrop/hooks'
import { usePollFarmsData } from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'

import BountyCard from './components/BountyCard'

import { getAprData, getCakeVaultEarnings } from './helpers'

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const StyledButton = styled.button`
  background: linear-gradient(45deg, #ff6b6b, #f06595);
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  padding: 12px 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background: linear-gradient(45deg, #ff4e50, #f04888);
  }

  &:disabled {
    background: linear-gradient(45deg, #d3d3d3, #b0b0b0);
    cursor: not-allowed;
  }

  &::before {
    content: 'GO';
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    color: gold;
    font-weight: bold;
    animation: ${slideIn} 0.5s forwards;
  }
`;

const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
  50% {
    text-shadow: 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3, 0 0 25px #62d5a3;
  }
  100% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
`;
const AnimatedText = styled.h1`
  font-size: 55px; // Adjust as needed
  color: white;
  text-align: center;
  width: 100%;
  animation: ${runningLightAnimation} 3s linear infinite;
 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(98, 213, 163, 0.3) 0%, rgba(98, 213, 163, 0) 70%);
    z-index: -1;
  }
`;
const ScrollBox = styled(Box)`
background-color: rgba(0,0,0);
padding: 10px;
width: 220px;
margin-top: 20px;
margin-bottom: -10px;
border: 0.05px solid ${({ theme }) => theme.colors.secondary};
border-radius: 10px;
${({ theme }) => theme.mediaQueries.md} {
  background-color: rgba(0,0,0);
  width: 220px;
  margin-bottom: -20px;
  padding: 10px;
  border: 0.05px solid ${({ theme }) => theme.colors.text};
  border-radius: 10px;
}
`
const ImageWrapper = styled.div`

margin: 0 auto;
margin-right: 5px;
${({ theme }) => theme.mediaQueries.md} {

  margin: 0 auto;
  margin-right: 5px;

}
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const StyledModalContainer4 = styled(Card)`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.cardBorder};
  background-color: rgba(0,0,0);
}
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    border: solid 1px ${({ theme }) => theme.colors.cardBorder};
    background-color: rgba(0,0,0);

`



const Faucet: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)

  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const cakeAutoVault = { ...cakePool, isAutoVault: true }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])


  const all = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL110000')



 

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyAllPools = useMemo(
    () =>
      all.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()



 

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }


  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
          <Flex alignItems="center" justifyContent="space-between">
          <AnimatedText>VizslaSwap Bonus System</AnimatedText>

      </Flex>
      
          </Flex>
        </Flex>
      </PageHeader>
 
      <Page>
      <Flex alignItems="center" justifyContent="center" mt="-20px" > 
      <BountyCard />
      </Flex>

      </Page>
    </>
  )
}

export default Faucet
