import React from 'react'
import styled from 'styled-components'
import { Flex, Skeleton, Text , Image} from '@pancakeswap/uikit'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'

interface TopFarmPoolProps {
  title: string
  percentage: number
  index: number
  image: string
  visible: boolean
}

const StyledWrapper = styled(Flex)<{ index: number }>`
  position: relative;
  margin-left: 30px;
  ${({ theme }) => theme.mediaQueries.md} {
    position: relative;
    margin-left: 70px;
  }
`

const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;

}
`
const AbsoluteWrapper = styled(Flex)<{ visible: boolean; index: number; topOffset: string  }>`
  position: absolute;
  top: ${({ topOffset }) => topOffset};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity, margin-top, 0.4s ease-out;
  flex-direction: column;

  ${({ index, theme }) =>
    index > 0
      ? `
         ${theme.mediaQueries.sm} {
           top: 0;
           padding-left: 16px;
         }
       `
      : `padding-right: 16px;`}
`

const TopFarmPool: React.FC<TopFarmPoolProps> = ({ title, percentage, index, visible, image }) => {
  const { t } = useTranslation()
  
  const topOffset = () => {
    if (index >= 0 && index < 2) {
      return '0px'
    }

    if (index >= 2 && index < 2) {
      return '0px'
    }

    return '0px'
  }

  return (
    <StyledWrapper index={index}>
      <AbsoluteWrapper index={index} visible={visible} topOffset={topOffset()}>
      <Flex flexDirection="column" alignItems="center" >
      <Flex justifyContent="center" alignItems="center" >
      {!image ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <ImageWrapper>
                  <img src={`/images/wallet/top/${image}.png`} alt="img" width="0px" height="0px" />
          </ImageWrapper>
      )}
                </Flex >
                <Flex justifyContent="center" alignItems="center">

        {title ? (
          <Text bold mb="4px" fontSize="0px" color="isten" mr="5px">
            {title}
          </Text>
        ) : (
          <Skeleton width={0} height={0} mb="8px" />
        )}
          </Flex >
          <Flex justifyContent="center" alignItems="center">

        {percentage ? (
          <Balance lineHeight="1.1" fontSize="0px" bold unit="%" decimals={2} value={percentage} mr="4px"/>
        ) : (
          <Skeleton width={0} height={0} />
        )}
        {percentage ? (
          <Text fontSize="0px" color="secondary">
            {t('APR')}
          </Text>
        ) : (
          <Skeleton width={0} height={0} mt="4px" />
        )}
                  </Flex >
                  </Flex >

      </AbsoluteWrapper>
    </StyledWrapper>
  )
}

export default TopFarmPool
