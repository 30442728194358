import React, { useState, useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core/core/core';
import { ethers } from 'ethers';
import styled from 'styled-components';
import { Box, Button, Input as UIKitInput, Text, ButtonMenu, ButtonMenuItem } from '@pancakeswap/uikit';
import { Link, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';  // Supabase importálása
import Kep from './Kep';
import Gift from './Gift';

// Supabase kliens létrehozása
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);
// Kontraktus ABI és cím
const contractABI = [
  {
    "inputs": [
        {
            "internalType": "string",
            "name": "_newName",
            "type": "string"
        }
    ],
    "name": "changeName",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "user",
            "type": "address"
        },
        {
            "indexed": false,
            "internalType": "string",
            "name": "oldName",
            "type": "string"
        },
        {
            "indexed": false,
            "internalType": "string",
            "name": "newName",
            "type": "string"
        }
    ],
    "name": "NameChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "address",
            "name": "user",
            "type": "address"
        },
        {
            "indexed": false,
            "internalType": "string",
            "name": "name",
            "type": "string"
        }
    ],
    "name": "NameRegistered",
    "type": "event"
  },
  {
    "inputs": [
        {
            "internalType": "string",
            "name": "_name",
            "type": "string"
        }
    ],
    "name": "registerName",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
        {
            "internalType": "string",
            "name": "_name",
            "type": "string"
        }
    ],
    "name": "getAddress",
    "outputs": [
        {
            "internalType": "address",
            "name": "",
            "type": "address"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
        {
            "internalType": "address",
            "name": "_user",
            "type": "address"
        }
    ],
    "name": "getName",
    "outputs": [
        {
            "internalType": "string",
            "name": "",
            "type": "string"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getUserCount",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471'; // Kontraktus címe

// Stíluselemek a NameRegistrationPage-hez
const Container = styled(Box)`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  border: 2px solid #5a9f98;
  border-radius: 12px;
  background-color: #000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Title = styled.h1`
  margin-bottom: 0;
  font-size: 2rem;
  color: #62d5a3;
`;

const ArrowIcon = styled.span`
  margin-left: 10px;
  font-size: 1.5rem;
  color: #62d5a3;
  display: flex;
  align-items: center;
`;

const Input = styled(UIKitInput)`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 1rem;
  color: #fff;
  border: none;
  background-color: #000;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #62d5a3;
  color: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #4abf88;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #3aa47b;
    transform: translateY(0);
  }
`;

const InfoText = styled(Text)`
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #e5e5e5;
`;

const NameRegistrationPage: React.FC = () => {
  const { account } = useWeb3React();
  const [name, setName] = useState<string>('');
  const [newName, setNewName] = useState<string>('');
  const [currentName, setCurrentName] = useState<string | null>(null);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false); // Alapértelmezett állapot: csukva
  const provider = useMemo(() => new ethers.providers.Web3Provider(window.ethereum), []);
  const contract = useMemo(() => new ethers.Contract(contractAddress, contractABI, provider), [provider]);
  const location = useLocation();


  // Törlési funkció
  const handleDeleteUserData = async () => {
    if (!account) return;

    try {
        // Töröljük a felhasználó sorát a Supabase-ből az account alapján
        await supabase
            .from('user_data')
            .delete()
            .eq('account', account);

        // Törlés után frissítjük a felhasználói adatokat az okos szerződésből
        const updatedName = await contract.getName(account);
        setCurrentName(updatedName);

        alert('User data deleted and refreshed successfully!');
    } catch (error) {
        console.error('Error deleting or refreshing user data:', error);
        alert('Error deleting or refreshing user data');
    }
};


  useEffect(() => {
    if (!account) return;

    const fetchUserData = async () => {
      try {
        const name = await contract.getName(account);
        setCurrentName(name);

        const userCount = await contract.getUserCount();
        setTotalUsers(userCount.toNumber());
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [account, contract]);

  const getActiveIndex = (pathname: string): number => {
    if (
      pathname.includes('/lockedstaking/all') ||
      pathname.includes('/lockedstaking/history')
    ) {
      return 0
    }
    return 2
  }
  useEffect(() => {
    if (!account) return;

    const fetchUserData = async () => {
      try {
        const name = await contract.getName(account);
        setCurrentName(name);

        const userCount = await contract.getUserCount();
        setTotalUsers(userCount.toNumber());
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [account, contract]);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const userCount = await contract.getUserCount();
        setTotalUsers(userCount.toNumber());
      } catch (error) {
        console.error('Error fetching total user count:', error);
      }
    };

    fetchTotalUsers();
  }, [contract]);

  const handleRegisterName = async () => {
    if (!account || !name) return;

    try {
      const signer = provider.getSigner();
      const contractWithSigner = contract.connect(signer);
      await contractWithSigner.registerName(name);
      alert('Name registered successfully!');
    } catch (error) {
      console.error('Error registering name:', error);
      alert('Error registering name');
    }
  };

  const handleChangeName = async () => {
    if (!account || !newName) return;

    try {
      const signer = provider.getSigner();
      const contractWithSigner = contract.connect(signer);
      await contractWithSigner.changeName(newName);
      alert('Name changed successfully!');
    } catch (error) {
      console.error('Error changing name:', error);
      alert('Error changing name');
    }
  };

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <MenuContainer>
        <ButtonMenu activeIndex={getActiveIndex(location.pathname)} scale="sm" variant="subtle">
          <ButtonMenuItem id="chat-nav-link" to="/chat" as={Link}>
            Public
          </ButtonMenuItem>
          <ButtonMenuItem id="name-reg-nav-link" to="/privatechat" as={Link}>
            Private
          </ButtonMenuItem>
          <ButtonMenuItem id="name-reg-nav-link" to="/nameregistration" as={Link}>
            Settings
          </ButtonMenuItem>
        </ButtonMenu>
      </MenuContainer>
      <Container>
      <TitleContainer>
      <Title  onClick={handleDeleteUserData}>Data Update ↻</Title>
      </TitleContainer>
      </Container>
      <Container>

        <TitleContainer onClick={toggleOpen}>
          <Title>Name Registration</Title>
          <ArrowIcon>{isOpen ? '▲' : '▼'}</ArrowIcon>
        </TitleContainer>
        {isOpen && (
          <>
            <InfoText>Your current name: {currentName || 'No name registered'}</InfoText>
            <InfoText>Total registered users: {totalUsers}</InfoText>
            <Input
              placeholder="Enter name to register"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <StyledButton onClick={handleRegisterName}>Register Name</StyledButton>
            <Input
              placeholder="Enter new name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <StyledButton onClick={handleChangeName}>Change Name</StyledButton>
          </>
        )}
      </Container>
      <Kep />
     </>
  );
};


export default NameRegistrationPage;