import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import { Heading, Flex, Skeleton, Text, Button, Box} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault  } from 'state/pools/hooks'
import { usePool4 , usePool5, usePool6 , usePool7, usePool8 , usePool9, usePool10, usePool11, usePool12, usePool13, usePool14, usePool15, usePool16, usePool17, usePool18 , usePool19, usePool20, usePool21 , usePool22, usePool23, usePool24, usePool25, usePool26, usePool27, usePool29, usePool28, usePool30, usePool31, usePool32, usePool33, usePool34, usePool35, usePool36, usePool37, usePool38, usePool39, usePool40, usePool41, usePool42,usePool43, usePool44, usePool45, usePool47, usePool46, usePool48, usePool49, usePool50, usePool51, usePool52, usePool53, usePool54, usePool55, usePool56 ,usePool57 ,usePool58} from 'hooks/useTokenBalance'
import { usePollFarmsData , usePriceBnbBusd, usePriceCakeBusd , usePriceHVIBusd, usePriceLameaBusd , usePriceETCBusd , usePriceBABYVIZSLABusd , usePriceBVCBusd ,usePriceEXZOBusd , usePriceETHBusd, usePriceATALIRABusd, usePriceLTCBusd,usePriceliraBusd, usePriceLGCBusd, usePricebabyBusd, usePricecubyBusd, usePricefrtcBusd, usePricebeethBusd } from 'state/farms/hooks'
import { getAddress, getHVIAddress , getCakeAddress, getWbnbAddress,geLiraAddress, getethAddress, getLAMEAAddress, getbvcAddress, getbabyvizslaAddress , getltcAddress, getetcAddress, getataliraAddress, getexzoAddress, getlgcAddress, getbabyAddress, getcubyAddress, getFRTCAddress, getbeethAddress} from 'utils/addressHelpers'
import { getBalanceNumber, formatLocalisedCompactNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import Loading from 'components/Loading'
import Balance from 'components/Balance'
import PoolCard from './components/PoolCard'
import CakeVaultCard from './components/CakeVaultCard'
import PoolTabButtons from './components/PoolTabButtons'
import BountyCard from './components/BountyCard'
import HelpButton from './components/HelpButton'
import InfoCard from './components/InfoCard'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const PoolControls = styled(Flex)`
display: flex;
width: 100%;
align-items: center;
position: relative;
 
justify-content: space-between;
flex-direction: column;
margin-bottom: 50px;
border-radius: 16px;


${({ theme }) => theme.mediaQueries.xl} {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 32px;
  background-color: rgb(19,47,46) ;
  border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
  margin-bottom: 50;
}
${({ theme }) => theme.mediaQueries.md} {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 32px;
  background-color: rgb(19,47,46) ;
  border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
  margin-bottom: 50;
`
const ScrollBox = styled(Box)`
background-color: rgba(0,0,0);
padding: 10px;
width: 210px;
margin-top: 20px;
margin-bottom: -10px;
border: 0.05px solid ${({ theme }) => theme.colors.secondary};
border-radius: 10px;
${({ theme }) => theme.mediaQueries.md} {
  background-color: rgba(0,0,0);
  width: 210px;
  margin-bottom: -20px;
  padding: 10px;
  border: 0.05px solid ${({ theme }) => theme.colors.text};
  border-radius: 10px;
}
`
const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
margin-right: 5px;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;
  margin-right: 5px;

}
`
const ButtonPro = styled(Button)`
  background: #000000; /* Fekete háttérszín */
  color: #ffffff; 
  border: 2px solid #62d5a3; /* Zöld szegély */
  border-radius: 6px; /* Enyhe kerekítés */
  padding: 10px 10px;
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase; /* Nagybetűs szöveg */
  transition: all 0.3s ease;
   display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Gomb feletti elem */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #5fd0a0; /* Zöld háttérszín */
    transition: left 0.3s ease;
    z-index: 0; /* Háttér szín */
  }

  &:hover {
    color: transparent; /* Szöveg eltüntetése hover állapotban */
    background: #5fd0a0; /* Gomb háttérszín változás */
    border-color: #5fd0a0; /* Zöld szegély változás */
  }

  &:hover:before {
    left: 0; /* Zöld háttér kitöltése jobbra tolódáskor */
  }

  &:active {
    background: #1b5e20; /* Nyomás szín */
    transform: scale(0.98);
  }

  span {
    position: relative;
    z-index: 1; /* Szöveg a háttér felett */
  }

  /* Helyettesítő szöveg a hover állapotban */
  &::after {
    content: 'GO'; /* Helyettesítő szöveg */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    transition: opacity 0.3s ease;
    opacity: 0; /* Alapértelmezett rejtett állapot */
    z-index: 1;
  } 

  &:hover::after {
    opacity: 1; /* Helyettesítő szöveg megjelenítése hover állapotban */
  }
`;
const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'pancake_pool_staked' })
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const cakeAutoVault = { ...cakePool, isAutoVault: true }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  const vizslapools = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol3  === 'VIZSLASWAP')
  const hvipools = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol3  === 'HVI')
  const antiwhalepools = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol4  === 'ANTIWHALE')
  const all = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL1')

  const VIZSLAPRICE0 = usePriceCakeBusd()
  const HVIPRICE0 = usePriceHVIBusd()
  const HVIPRICE = HVIPRICE0.toNumber()
  const VIZSLAPRICE = VIZSLAPRICE0.toNumber()
  const WBNBPRICE0 = usePriceBnbBusd()
  const WBNBPRICE = WBNBPRICE0.toNumber()
  const ETHPPRICE0 = usePriceETHBusd()
  const ETHPRICE = ETHPPRICE0.toNumber()
  const ETCPRICE0 = usePriceETCBusd()
  const ETCPRICE = ETCPRICE0.toNumber()
  const LTCPRICE0 = usePriceLTCBusd()
  const LTCPRICE = LTCPRICE0.toNumber()
  const LAMEAPRICE0 = usePriceLameaBusd()
  const LAMEAPRICE = LAMEAPRICE0.toNumber()
  const ATALIRAPRICE0 = usePriceATALIRABusd()
  const ATALIRAPRICE = ATALIRAPRICE0.toNumber()
  const EXZOPRICE0 = usePriceEXZOBusd()
  const EXZOPRICE = EXZOPRICE0.toNumber()
  const BVCPRICE0 = usePriceBVCBusd()
  const BVCPRICE = BVCPRICE0.toNumber()
  const BABYVIZSLAPRICE0 = usePriceBABYVIZSLABusd()
  const BABYVIZSLAPRICE = BABYVIZSLAPRICE0.toNumber()
  const liraprice0 = usePriceliraBusd()
  const babyprice0 = usePricebabyBusd()
  const babyprice = babyprice0.toNumber()
  const cubyprice0 = usePricecubyBusd()
  const cubyprice = cubyprice0.toNumber()
  const frtcprice0 = usePricefrtcBusd()
  const frtcprice = frtcprice0.toNumber()
const liraprice = liraprice0.toNumber()
const lgcprice0 = usePriceLGCBusd()
const lgcprice = lgcprice0.toNumber()
const beethrpcei0 = usePricebeethBusd()
const beethprice = beethrpcei0.toNumber()

  const POOL4 = getBalanceNumber(usePool4(getCakeAddress()))
  const POOL5 = getBalanceNumber(usePool5(getCakeAddress()))
  const POOL6 = getBalanceNumber(usePool6(getCakeAddress()))
  const POOL7 = getBalanceNumber(usePool7(getCakeAddress()))
  const POOL8 = getBalanceNumber(usePool8(getCakeAddress()))
  const POOL9 = getBalanceNumber(usePool9(getCakeAddress()))
  const POOL10 = getBalanceNumber(usePool10(getCakeAddress()))
  const POOL11 = getBalanceNumber(usePool11(getCakeAddress()))
  const POOL12 = getBalanceNumber(usePool12(getCakeAddress()))
  const POOL13 = getBalanceNumber(usePool13(getCakeAddress()))
  const POOL14 = getBalanceNumber(usePool14(getCakeAddress()))
  const POOL15 = getBalanceNumber(usePool15(getCakeAddress()))
  const POOL16 = getBalanceNumber(usePool16(getCakeAddress()))
  const POOL17 = getBalanceNumber(usePool17(getCakeAddress()))
  const POOL18 = getBalanceNumber(usePool18(getCakeAddress()))
  const POOL19 = getBalanceNumber(usePool19(getCakeAddress()))
  const POOL20 = getBalanceNumber(usePool20(getCakeAddress()))
  const POOL21 = getBalanceNumber(usePool21(getCakeAddress()))
  const POOL22 = getBalanceNumber(usePool22(getHVIAddress()))
  const POOL23 = getBalanceNumber(usePool23(getHVIAddress()))
  const POOL24 = getBalanceNumber(usePool24(getHVIAddress()))
  const POOL25 = getBalanceNumber(usePool25(getHVIAddress()))
  const POOL26 = getBalanceNumber(usePool26(getHVIAddress()))
  const POOL27 = getBalanceNumber(usePool27(getHVIAddress()))
  const POOL28 = getBalanceNumber(usePool28(getHVIAddress()))
  const POOL29 = getBalanceNumber(usePool29(getHVIAddress()))
  const POOL30 = getBalanceNumber(usePool30(getWbnbAddress()))
  const POOL31 = getBalanceNumber(usePool31(getethAddress()))
  const POOL32 = getBalanceNumber(usePool32(getetcAddress()))
  const POOL33 = getBalanceNumber(usePool33(getltcAddress()))
  const POOL34 = getBalanceNumber(usePool34(getLAMEAAddress()))
  const POOL35 = getBalanceNumber(usePool35(getataliraAddress()))
  const POOL36 = getBalanceNumber(usePool36(getexzoAddress()))
  const POOL37 = getBalanceNumber(usePool37(getbvcAddress()))
  const POOL38 = getBalanceNumber(usePool38(getbabyvizslaAddress()))
  const POOL39 = getBalanceNumber(usePool39(geLiraAddress()))
  const POOL40 = getBalanceNumber(usePool40(getCakeAddress()))
  const POOL41 = getBalanceNumber(usePool41(getHVIAddress()))
  const POOL42 = getBalanceNumber(usePool42(getlgcAddress()))
  const POOL43 = getBalanceNumber(usePool43(getCakeAddress()))
  const POOL44 = getBalanceNumber(usePool44(getHVIAddress()))
  const POOL45 = getBalanceNumber(usePool45(getbabyAddress()))
  const POOL46 = getBalanceNumber(usePool46(getCakeAddress()))
  const POOL47 = getBalanceNumber(usePool47(getHVIAddress()))
  const POOL48 = getBalanceNumber(usePool48(getlgcAddress()))
  const POOL49 = getBalanceNumber(usePool49(getlgcAddress()))
  const POOL50 = getBalanceNumber(usePool50(getcubyAddress()))
  const POOL51 = getBalanceNumber(usePool51(getCakeAddress()))
  const POOL52 = getBalanceNumber(usePool52(getHVIAddress()))
  const POOL53 = getBalanceNumber(usePool53(getFRTCAddress()))
  const POOL54 = getBalanceNumber(usePool54(getCakeAddress()))
  const POOL55 = getBalanceNumber(usePool55(getHVIAddress()))
  const POOL56 = getBalanceNumber(usePool56(getbeethAddress()))
  const POOL57 = getBalanceNumber(usePool57(getCakeAddress()))
  const POOL58 = getBalanceNumber(usePool58(getHVIAddress()))

  const MAJDNEM  = POOL4 + POOL5 + POOL54 + POOL57 + POOL51 + POOL6 + POOL7 + POOL8 + POOL9 + POOL10 + POOL11 + POOL12 + POOL13 + POOL14 + POOL15 + POOL16 + POOL17 + POOL18 + POOL19 + POOL20 + POOL21 + POOL40 + POOL43 + POOL46
  const MAJDNEM2 = POOL22 + POOL23 + POOL24 + POOL25 + POOL26 + POOL27 + POOL28 + POOL29 + POOL41 + POOL44 + POOL47 + POOL52 + POOL55 + POOL58
  const MAJNDEM3 = POOL42 + POOL48 + POOL49 
  const FULL1 = MAJDNEM * VIZSLAPRICE
  const FULL2 = MAJDNEM2 * HVIPRICE * 1000000000
  const FULL4 = POOL30 * WBNBPRICE
  const FULL5 = POOL31 * ETHPRICE
  const FULL6 = POOL32 * ETCPRICE
  const FULL7 = POOL33 * LTCPRICE
  const FULL8 = POOL34 * LAMEAPRICE
  const FULL9 = POOL35 * ATALIRAPRICE * 1000000000
  const FULL10 = POOL36 * EXZOPRICE * 1000000000
  const FULL11 = POOL37 * BVCPRICE
  const FULL12 = POOL38 * BABYVIZSLAPRICE
  const FULL13 = POOL39 * liraprice
  const FULL14 = MAJNDEM3 * lgcprice
  const FULL15 = POOL45 * babyprice
  const FULL16 = POOL50 * cubyprice
  const FULL17 = POOL53 * frtcprice
  const FULL18 = POOL56 * beethprice
  const FULL3 = FULL1 + FULL2  + FULL4 + FULL5 + FULL6 + FULL7 + FULL8 + FULL9 + FULL10 + FULL11 + FULL12 + FULL13 + FULL14 + FULL15 + FULL16 + FULL17 + FULL18
  
  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyAllPools = useMemo(
    () =>
      all.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const showFinishedPools = location.pathname.includes('history')
  const showvizslapools = location.pathname.includes('vizslapools')
  const showhvipools = location.pathname.includes('hvipools')
  const showantiwhalepools = location.pathname.includes('antiwhalepools')
  const showall = location.pathname.includes('all')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools
  if (showFinishedPools) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
  } else {
    chosenPools = stakedOnly ? stakedOnlyAllPools : openPools
  }
  
  if (showvizslapools) {
    chosenPools = stakedOnly ? stakedOnlyAllPools : vizslapools
  } 
  if (showhvipools) {
    chosenPools = stakedOnly ? stakedOnlyAllPools : hvipools
  } 
  if (showantiwhalepools) {
    chosenPools = stakedOnly ? stakedOnlyAllPools : antiwhalepools
  } 
  if (showall) {
    chosenPools = stakedOnly ? stakedOnlyAllPools : all
  } 



  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
        pool.isAutoVault ? (
          <CakeVaultCard key="auto-cake" pool={pool} showStakedOnly={stakedOnly} />
        ) : (
          <PoolCard key={pool.sousId} pool={pool} account={account} />
        ),
      )}
    </CardLayout>
  )

  const tableLayout = <PoolsTable pools={chosenPools} account={account} userDataLoaded={userDataLoaded} />

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="secondary" mb="24px">
              {t('Staking Pools')}
            </Heading>
            <Heading scale="md" color="text">
              {t('Just stake some tokens to earn.')}
            </Heading>
            <Heading scale="md" color="text">
              {t('High APR, low risk.')}
            </Heading>
            <ScrollBox>
          <Flex justifyContent="center" alignItems="center" >
          <Flex flexDirection="column" alignItems="center" >
      <ImageWrapper>
        <img src="/images/pixel/X1.png" alt="ifo bunny" width="50px" height="50px"/>
      </ImageWrapper>
      </Flex >

          <Flex flexDirection="column" alignItems="left" >


    <Text color="primary"  fontSize="14px" mr="5px">
        {t('Total Value Locked')}
      </Text>
      {FULL3 ? (
        <Balance fontSize="21px" prefix="$" bold decimals={0} value={FULL3} />
      ) : (
        <Skeleton width={60} height={16} />
      )}
    </Flex >
    </Flex>
    </ScrollBox>
          
         
          </Flex>
          <Flex flex="1" height="fit-content" justifyContent={['left', null, 'right']} alignItems="center" mt={['24px', null, '0']}>
            <BountyCard />
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <PoolControls>
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
          <FilterContainer>
            <LabelWrapper>
              <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
                {t('Sort by')}
              </Text>
              <ControlStretch>
                <Select
                  options={[
                    {
                      label: t('Hot'),
                      value: 'hot',
                    },
                    {
                      label: t('APR'),
                      value: 'apr',
                    },
                    {
                      label: t('Earned'),
                      value: 'earned',
                    },
                    {
                      label: t('Total staked'),
                      value: 'totalStaked',
                    },
                  ]}
                  onChange={handleSortOptionChange}
                />
              </ControlStretch>
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
                {t('Search')}
              </Text>
              <SearchInput onChange={handleChangeSearchQuery} placeholder="Search Pools" />
            </LabelWrapper>
          </FilterContainer>
        </PoolControls> 
        <Flex alignItems="center" justifyContent="center" mb="35px" flex="1" height="fit-content" >
        <ButtonPro as={Link} width={43} height={35}  to="/pools/all"  variant="secondary" mr={10}>
        <ButtonText color="primary" bold fontSize="14px">
   {t('ALL')}
</ButtonText>    
  </ButtonPro>
  <ButtonPro as={Link} width={43} height={35}  to="/pools/hvipools" variant="secondary" mr={10}>
      <ButtonText color="primary" bold fontSize="14px">
   {t('HVI')}
</ButtonText>
      </ButtonPro>
      <ButtonPro as={Link} width={105} height={35}  to="/pools/vizslapools" variant="secondary" mr={10}>
      <ButtonText color="primary" bold fontSize="14px">
   {t('VIZSLASWAP')}
</ButtonText>      </ButtonPro>
      <ButtonPro as={Link} width={105} height={35}  to="/pools/antiwhalepools" variant="secondary" mr={10}>
      <ButtonText color="primary" bold fontSize="14px">
   {t('ANTIWHALE')}
</ButtonText>
      </ButtonPro> 
      </Flex>
        {showFinishedPools && (
          <Text fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {viewMode === ViewMode.CARD ? cardLayout : tableLayout}
        <div ref={loadMoreRef} />
      </Page>
    </>
  )
}

export default Pools