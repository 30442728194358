import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core' 
import { Heading, Flex, Skeleton, Text, Message, Box, ButtonMenu,ButtonMenuItem} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault  } from 'state/poolsTimerV2Locked/hooks'
import {  useLocked, useLocked2, useLocked3} from 'hooks/useTokenBalance'
import { usePollFarmsData , usePriceCakeBusd, usePriceHVIBusd } from 'state/farms/hooks'
import { getCakeAddress, getHVIAddress} from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { latinise } from 'utils/latinise'  
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import Loading from 'components/Loading'
import Balance from 'components/Balance'
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'

import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const PoolControls = styled(Flex)`
display: flex;
width: 100%;
align-items: center;
position: relative;

justify-content: space-between;
flex-direction: column;
margin-bottom: 35px;
background-color: rgb(19,47,46) ;
border-radius: 16px;

${({ theme }) => theme.mediaQueries.sm} {
  flex-direction: row;
    flex-wrap: wrap;
    border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
    padding: 20px 32px;
    margin-bottom: 50;
}
`
const ScrollBox = styled(Box)`
background-color: rgba(0,0,0);
padding: 10px;
width: 210px;
margin-top: 20px;
margin-bottom: -10px;
border: 0.05px solid ${({ theme }) => theme.colors.secondary};
border-radius: 10px;
${({ theme }) => theme.mediaQueries.md} {
  background-color: rgba(0,0,0);
  width: 210px;
  margin-bottom: -20px;
  padding: 10px;
  border: 0.05px solid ${({ theme }) => theme.colors.text};
  border-radius: 10px;
}
`
const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
margin-right: 5px;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;
  margin-right: 5px;

}
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Locked: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId !== 10)
    const cakeAutoVault = { ...cakePool, isAutoVault: false }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  
  const all = pools.filter((pool) => pool.sousId !== 10 && pool.tokensymbol5  === 'ALL99998')
  const finished = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED5')

  const VIZSLAPRICE0 = usePriceCakeBusd()
  const VIZSLAPRICE = VIZSLAPRICE0.toNumber()
  const HVIPRICE0 = usePriceHVIBusd()
  const HVIPRICE = HVIPRICE0.toNumber()
  const LOCKED2 = getBalanceNumber(useLocked2(getHVIAddress()))
 const LOCKED = getBalanceNumber(useLocked(getCakeAddress()))
 const LOCKED3 = getBalanceNumber(useLocked3(getCakeAddress()))
const LOCKEDFULL4 = LOCKED3 * VIZSLAPRICE
const LOCKEDFULL3 = LOCKED * VIZSLAPRICE
const LOCKEDFULL2 = LOCKED2 * HVIPRICE * 1000000000
const LOCKEDFULL = LOCKEDFULL3 + LOCKEDFULL2 + LOCKEDFULL4

  
  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyAllPools = useMemo(
    () =>
      all.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])
  const getActiveIndex2 = (pathname: string): number => {
    if (
      pathname.includes('/lockedstakingv2/all') ||
      pathname.includes('/lockedstaking/al')
    ) {
      return 0
    }
    return 1
  }
  const showFinishedPools = location.pathname.includes('history')
  const showall = location.pathname.includes('all')
  const showfinished = location.pathname.includes('finished')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools = [];

  if (showFinishedPools) {
    chosenPools = finishedPools;
  } else if (showall) {
    chosenPools = all;
  } else if (showfinished) {
    chosenPools = finished;
  }
  


  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible);
  chosenPoolsLength.current = chosenPools.length;

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
         (
          <PoolCard key={pool.sousId} pool={pool} account={account}  />
        ),
      )}
    </CardLayout>
  ) 
      
 
  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="secondary" mb="24px">
              {t('Locked Staking V2')}
            </Heading>
            <Heading scale="md" color="text">
              {t('Increasing APR driven by the community.')}
            </Heading>
            <Heading scale="md" color="text">
              {t('long term staking..')}
            </Heading>
            <ScrollBox>
          <Flex justifyContent="center" alignItems="center" >
          <Flex flexDirection="column" alignItems="center" >
      <ImageWrapper>
        <img src="/images/pixel/Locked.png" alt="ifo bunny" width="50px" height="50px"/>
      </ImageWrapper>
      </Flex >

          <Flex flexDirection="column" alignItems="left" >


    <Text color="primary"  fontSize="14px" mr="5px">
        {t('Total Value Locked')}
      </Text>
      {LOCKEDFULL ? (
        <Balance fontSize="21px" prefix="$" bold decimals={0} value={LOCKEDFULL} />
      ) : (
        <Skeleton width={60} height={16} />
      )}
    </Flex >
    </Flex>
    </ScrollBox>
          
    <Flex justifyContent="center" alignItems="center" mt="40px" mb="-20px">

<ButtonMenu activeIndex={getActiveIndex2(location.pathname)}  scale="sm" variant="subtle">
    <ButtonMenuItem id="swap-nav-link"  to="/lockedstakingv2/all"  as={Link}>
    {t('V2')} 
  </ButtonMenuItem>
  <ButtonMenuItem id="swap-nav-link" to="/lockedstaking/all"  as={Link} mt="4px">
      {t('V1 (old)')}
    </ButtonMenuItem>
</ButtonMenu>
</Flex>
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <Message variant="warning" mb="50px" >
           <Box>
             <Text fontWeight="bold">{t('Terms of use -')}</Text>
             <Text as="p">{t('- The APR depends on the total number of locked tokens (maximum 60%)')}</Text>
             <Text as="p">{t('- After the deadline, the withdrawal fee is 0.5%')}</Text>
             <Text as="p">{t('- You can enter as many times as you want, the duration will not be updated')}</Text>
             <Text as="p">{t('-  In the case of withdrawal, everything must be removed at once so that the duration can be updated')}</Text>
           </Box>
         </Message>
        {showFinishedPools && (
          <Text fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {account && !userDataLoaded && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {viewMode === ViewMode.CARD ? cardLayout : cardLayout}
        <div ref={loadMoreRef} />
      </Page>
    </>
  )
}

export default Locked
