import React, { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js/bignumber'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useFarmUser } from 'state/farms/hooks'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { getaceAddress, getataliraAddress, getbabyAddress, getbabyvizslaAddress, getbtcAddress, getbtntyAddress, getbuildAddress, getBusdAddress, getbvcAddress, getCakeAddress , getcubyAddress, getetcAddress, getethAddress, getexzoAddress, getFarm2Address, getfpsAddress, getHVIAddress, getLAMEAAddress, getlgcAddress, getliraAddress, getltcAddress, gettripAddress, getVNAddress, getWbnbAddress} from 'utils/addressHelpers'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletHarvest from './WalletHarvest'


export enum WalletView {
  HARVEST,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = new BigNumber('2000000000') // 2 Gwei
export const LOW_GOD_BALANCE = new BigNumber('2000000000') // 2 Gwei

const ModalHeader = styled(UIKitModalHeader)`
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 16px 24px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.HARVEST, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance } = useTokenBalance(getCakeAddress())
  const { balance: cakeBalance2 } = useTokenBalance(getHVIAddress())
  const { balance: cakeBalance3 } = useTokenBalance(getBusdAddress())
  const { balance: cakeBalance4 } = useTokenBalance(getLAMEAAddress())
  const { balance: cakeBalance5 } = useTokenBalance(getlgcAddress())
  const { balance: cakeBalance6 } = useTokenBalance(getbvcAddress())
  const { balance: cakeBalance7 } = useTokenBalance(getbabyvizslaAddress())
  const { balance: cakeBalance8 } = useTokenBalance(getWbnbAddress())
  const { balance: cakeBalance9 } = useTokenBalance(getfpsAddress())
  const { balance: cakeBalance10 } = useTokenBalance(getexzoAddress())
  const { balance: cakeBalance11 } = useTokenBalance(getataliraAddress())
  const { balance: cakeBalance12 } = useTokenBalance(gettripAddress())
  const { balance: cakeBalance13 } = useTokenBalance(getbuildAddress())
  const { balance: cakeBalance14 } = useTokenBalance(getliraAddress())
  const { balance: cakeBalance15 } = useTokenBalance(getaceAddress())
  const { balance: cakeBalance16 } = useTokenBalance(getbtntyAddress())
  const { balance: cakeBalance17 } = useTokenBalance(getltcAddress())
  const { balance: cakeBalance18 } = useTokenBalance(getethAddress())
  const { balance: cakeBalance19 } = useTokenBalance(getetcAddress())
  const { balance: cakeBalance1X } = useTokenBalance(getFarm2Address())
  const { balance: cakeBalance20 } = useTokenBalance(getbabyAddress())
  const { balance: cakeBalance21 } = useTokenBalance(getbtcAddress())
    const { balance: cakeBalance22 } = useTokenBalance(getcubyAddress())
  const { balance: cakeBalanceN } = useTokenBalance(getVNAddress())


  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  const hasLowGodBalance = fetchStatus === FetchStatus.SUCCESS && balance.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod2Balance = cakeBalance.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod3Balance = cakeBalance2.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod4Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod5Balance = cakeBalance4.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod6Balance = cakeBalance5.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod7Balance = cakeBalance6.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod8Balance = cakeBalance7.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod9Balance = cakeBalance8.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod10Balance = cakeBalance9.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance11 = cakeBalance10.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance12 = cakeBalance11.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance13 = cakeBalance12.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance14 = cakeBalance13.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance15 = cakeBalance14.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance16 = cakeBalance15.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance17 = cakeBalance16.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance18 = cakeBalance17.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance19 = cakeBalance18.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance20 = cakeBalance19.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance21 = cakeBalance20.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance22 = cakeBalance21.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)

  const hasLowGodBalance1X = cakeBalance1X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)


  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <ModalContainer title={t('Welcome!')} minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Your Wallet')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        {view === WalletView.HARVEST && <WalletHarvest hasLowBnbBalance={hasLowBnbBalance}  onDismiss={onDismiss}  />}
      </ModalBody>
    </ModalContainer>
  )
}

export default WalletModal
