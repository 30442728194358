import React from 'react'
import BigNumber from 'bignumber.js/bignumber'
import styled from 'styled-components'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import {
  Flex,
  MetamaskIcon,
  Text,
  TooltipText,
  LinkExternal,
  TimerIcon,
  Skeleton,
  useTooltip,
  Box,
  Link,
  HelpIcon,
} from '@pancakeswap/uikit'
import { BASE_BSC_SCAN_URL } from 'config'
import { useBlock } from 'state/block/hooks'
import { useCakeVault } from 'state/pools/hooks'
import { Pool } from 'state/types'
import { useVote1, useVote2, useVote3, useVote4 } from 'hooks/useTokenBalance'
import { getAddress, getCakeAddress, getCakeVaultAddress } from 'utils/addressHelpers'
import { registerToken } from 'utils/wallet'
import { getBscScanLink } from 'utils'
import Balance from 'components/Balance'
import { getPoolBlockInfo } from 'views/Pools/helpers'

interface ExpandedFooterProps {
  pool: Pool
  account: string
}

const ExpandedWrapper = styled(Box)`
margin-bottom: -15px;
`
const ImageWrapper = styled.div`

${({ theme }) => theme.mediaQueries.md} {


}
`

const ExpandedFooter: React.FC<ExpandedFooterProps> = ({ pool, account }) => {
  const { t } = useTranslation()
  const { currentBlock } = useBlock()
  const {
    totalCakeInVault,
    fees: { performanceFee },
  } = useCakeVault()

  const {
    stakingToken,
    earningToken,
    totalStaked,
    startBlock,
    endBlock,
    stakingLimit,
    contractAddress,
    sousId,
    isAutoVault,
  } = pool

  const tokenAddress = earningToken.address ? getAddress(earningToken.address) : ''
  const poolContractAddress = getAddress(contractAddress)
  const cakeVaultContractAddress = getCakeVaultAddress()
  const isMetaMaskInScope = !!window.ethereum?.isMetaMask
  const isManualCakePool = sousId === 0
  const VOTE1 = getBalanceNumber(useVote1(getCakeAddress()))
  const VOTE2 = getBalanceNumber(useVote2(getCakeAddress()))
  const VOTE3 = getBalanceNumber(useVote3(getCakeAddress()))
  const VOTE4 = getBalanceNumber(useVote4(getCakeAddress()))
  const VOTEF = VOTE1 + VOTE2 + VOTE3 + VOTE4
  const { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay } =
    getPoolBlockInfo(pool, currentBlock)

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Subtracted automatically from each yield harvest and burned.'),
    { placement: 'bottom-start' },
  )

  const getTotalStakedBalance = () => {
    if (isAutoVault) {
      return getBalanceNumber(totalCakeInVault, stakingToken.decimals)
    }
    if (isManualCakePool) {
      const manualCakeTotalMinusAutoVault = new BigNumber(totalStaked).minus(totalCakeInVault)
      return getBalanceNumber(manualCakeTotalMinusAutoVault, stakingToken.decimals)
    }
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }

const god = VOTEF / 100
const god2 = totalStaked.div(god)
const god3 = god2.toNumber()
const god4 = god3 / 1000000000000000000

  return (
    <ExpandedWrapper>
        <Flex flexDirection="column"  justifyContent="center" alignItems="center">

        {god2 && god2.gte(0) ? (
            <>
              <Balance fontSize="21px" color="isten" value={god4} decimals={0} unit={t(`%`)} />
            </>
          ) : (
            <Skeleton width="90px" height="21px" />
          )}
          <Flex  justifyContent="center" alignItems="center">

          {totalStaked && totalStaked.gte(0) ? (
            <>
              <Balance small value={getTotalStakedBalance()} decimals={0} unit={t(` Voting Power`)} mr="5px"  />
              <ImageWrapper>
        <img src="/images/pixel/power.png" alt="ifo bunny" width="15px" height="15px"/>
      </ImageWrapper>
            </>
          ) : (
            <Skeleton width="90px" height="21px" />
          )}
        </Flex>
      </Flex>
    </ExpandedWrapper>
  )
}

export default React.memo(ExpandedFooter)
