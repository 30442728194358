import BigNumber from 'bignumber.js/bignumber'
import React from 'react'
import { CardBody, Flex, Text, CardRibbon} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import styled from 'styled-components'
import AprRow from './AprRow'
import { StyledCard } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'

const PoolCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  const { number, sousId, stakingToken, earningToken, isFinished, userData,withdrawtime, withdrawtime2, withdrawtime3, withdrawtime4} = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)
  
  const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 0px;
`
  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
    > 
      <CardFooter pool={pool} account={account} />

      <CardBody>

        <Flex  flexDirection="column">
          {account ? (
            <CardActions pool={pool} stakedBalance={stakedBalance} />
          ) : (
   
              <ConnectWalletButton />
          )}
        </Flex>
      </CardBody>
    </StyledCard>
  )
}

export default PoolCard
