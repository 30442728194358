import styled from 'styled-components';
import { Box, Button, Text } from '@pancakeswap/uikit';

const Container = styled(Box)`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  border: 2px solid #5a9f98;
  border-radius: 12px;
  background-color: #000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.h2`
  margin-bottom: 0;
  font-size: 2rem;
  color: #62d5a3;
`;

const ArrowIcon = styled.span`
  margin-left: 10px;
  font-size: 1.5rem;
  color: #62d5a3;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  margin-top: 20px;
`;

const ImageItem = styled.div`
  text-align: center;

  img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  p {
    color: #e5e5e5;
    font-size: 0.9rem;
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #62d5a3;
  color: #1e1e1e;
  border-radius: 8px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #4abf88;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #3aa47b;
    transform: translateY(0);
  }
`;

const InfoText = styled(Text)`
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #e5e5e5;
`;

export { Container, HeaderContainer, Title, ArrowIcon, Content, ImageGrid, ImageItem, StyledButton, InfoText };
