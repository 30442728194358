import BigNumber from 'bignumber.js/bignumber'
import { useLocation, Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Flex, Text, Box , Button, AutoRenewIcon, useModal} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import useToast from 'hooks/useToast'
import { PoolCategory } from 'config/constants/types'
import { Pool } from 'state/types'
import { getBusdAddress } from 'utils/addressHelpers'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import WalletModal2, { WalletView2, LOW_BNB_BALANCE2 } from '../../../../../components/Menu/UserMenu/WalletModal2'
 import ApprovalAction from './ApprovalAction'
import useUnstakePool from '../../../hooks/useUnstakePool'
import useStakePool from '../../../hooks/useStakePool'
 

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
interface CardActionsProps {
  pool: Pool
  isRemovingStake?: boolean
  onDismiss?: () => void
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ({ pool, stakedBalance ,isRemovingStake, onDismiss}) => {
  const { sousId, stakingToken, earningToken, harvest, poolCategory, userData, stakingLimit } = pool
  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE
  const [pendingTx, setPendingTx] = useState(false)
  const [hasReachedStakeLimit, setHasReachedStakedLimit] = useState(false)
  const stakeAmount = ('0.5')
  const { onUnstake } = useUnstakePool(sousId, pool.enableEmergencyWithdraw)
  const { toastSuccess, toastError } = useToast()
  const { onStake } = useStakePool(sousId, isBnbPool)
  const { t } = useTranslation()
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isStaked = stakedBalance.gt(0)
  const isLoading = !userData
  const LOW_GOD_BALANCE = stakeAmount // 2 Gwei
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance3 } = useTokenBalance(getBusdAddress())

  const hasLowGod2Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const [onPresentWalletModal] = useModal(<WalletModal2 initialView={WalletView2.WALLET_INFO} />)

 
  const callOptions = {
    gasLimit: 381000,
  }
  
  const handleConfirmClick = async () => {
    setPendingTx(true)

    if (isStaked) {
      // unstaking
      try {
         await onUnstake(stakeAmount, stakingToken.decimals)
        setPendingTx(false)  
        onDismiss()
       } catch (e) {
        setPendingTx(false) 
      }
    } else {
      try {
        // staking
        await onStake(stakeAmount, stakingToken.decimals)
        setPendingTx(false)
        onDismiss()
      } catch (e) {
        setPendingTx(false)
       }
    }
  }

   
 
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
      {!hasLowGod2Balance && (

      <Button variant="secondary2" mt="15px"  as={Link} to="/apeswap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56" >
             <ButtonText color="text" bold fontSize="14px">
   {t('Insufficient BUSD in your wallet')}
</ButtonText>
       </Button> 
                  )}

      {hasLowGod2Balance && (

           <ApprovalAction pool={pool} isLoading={isLoading} />
           )}

           {!isStaked && hasLowGod2Balance && (
           <Button 
        isLoading={pendingTx} 
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
        onClick={handleConfirmClick}
        mt="15px" 
        variant="secondary" 
      >
        {pendingTx ? t('Confirming') : t('Buy Key')}
      </Button>
                          )}

      {isStaked && (

      <Button
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
        onClick={handleConfirmClick}
        disabled={!stakeAmount || parseFloat(stakeAmount) === 0 || hasReachedStakeLimit}
        mt="15px"
        variant="secondary"
      >
        {pendingTx ? t('Confirming') : t('Open')}
      </Button>
            )}
            {!isStaked && (

 <Button variant="tertiary" mt="15px"  onClick={onPresentWalletModal}>
             <ButtonText color="text" bold fontSize="15px">
   {t('Check Token Balance')}
</ButtonText>
       </Button> 
            )}

      </Flex>
    </Flex>
  )
}

export default CardActions
