import React, { useState } from 'react'
import BigNumber from 'bignumber.js/bignumber'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Flex, ExpandableLabel, HelpIcon, useTooltip } from '@pancakeswap/uikit'
import { Pool } from 'state/types'
import { CompoundingPoolTag, ManualPoolTag } from 'components/Tags'
import ExpandedFooter from './ExpandedFooter'

interface FooterProps {
  pool: Pool
  account: string
  totalCakeInVault?: BigNumber
}

const CardFooter = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const Footer: React.FC<FooterProps> = ({ pool, account }) => {
  const { isAutoVault } = pool
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  const manualTooltipText = t('You must harvest and compound your earnings from this pool manually.')
  const autoTooltipText = t(
    'Any funds you stake in this pool will be automagically harvested and restaked (compounded) for you.',
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(isAutoVault ? autoTooltipText : manualTooltipText, {
    placement: 'bottom',
  })

  return (
    <CardFooter>
 
       <ExpandedFooter pool={pool} account={account} />
    </CardFooter>
  )
}

export default Footer
