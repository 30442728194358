import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import styled, { DefaultTheme, keyframes, css } from "styled-components";
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import {  Flex, Link, Text, Button, Box, Message, OpenNewIcon, SubMenu, SubMenuItem, Card, useMatchBreakpoints} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition' 
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault  } from 'state/poolsDrop6/hooks'
import { usePollFarmsData , usePriceCakeBusd } from 'state/farms/hooks'
import FlexLayout from 'components/Layout/Flex' 
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import Select, { OptionProps } from 'components/Select/Select'
import { useVaultApprove, useCheckVaultApprovalStatus } from 'views/FaucetPackages/hooks/useApprove';
import { Pool } from 'state/types'
import { getCakeAddress } from 'utils/addressHelpers'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import WalletModal2, { WalletView2, LOW_BNB_BALANCE2 } from 'components/Menu/UserMenu/WalletModal2' 
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'

const ModernButton = styled(Button)`
  background: transparent;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  padding: 12px 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, color 0.3s ease;

  &:hover {
    background: black;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background: black;
    color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    transform: scale(0.95);
  }

  &:disabled {
    background: transparent;
    border: 2px solid #d3d3d3;
    color: #d3d3d3;
    cursor: not-allowed;
  }
`;
const RainbowLight = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;
const ImageWrapper = styled.div`
margin: 0 auto;
${({ theme }) => theme.mediaQueries.md} {
  margin: 0 auto;

}
`
const ButtonText = styled(Text)`
display: none;
${({ theme }) => theme.mediaQueries.xs} {
  display: block;
}
`
const ModernBoxEffect = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;
const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
  50% {
    text-shadow: 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3, 0 0 25px #62d5a3;
  }
  100% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
`;  

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ScrollBox = styled(Box)`
  padding: 20px;
  background: radial-gradient(circle at top left, #c191e9, #8e4fbf, #7d3daf, transparent),
              radial-gradient(circle at bottom right, #ffffff, #f0e4f5, #d3b8df, transparent);
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #356fa5;
  width: 300px;
  margin: 20px;
  opacity: 0;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 320px;
  }
`;

const ScrollBox2 = styled(Box)`
  padding: 20px;
  background: radial-gradient(circle at top left, #fedb37, #9f7928, #8a6e2f, transparent),
              radial-gradient(circle at bottom right, #ffffff, #ffffac, #d1b464, transparent);
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #ffa500;
  width: 300px;
  margin: 20px;
  opacity: 0;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 320px;
  }
`;

const ScrollBox3 = styled(Box)`
  padding: 20px;
  background: radial-gradient(circle at top left, silver, #a9a9a9, #8c8c8c, transparent),
              radial-gradient(circle at bottom right, #ffffff, #e6e6e6, #b8b8b8, transparent);
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid silver;
  width: 300px;
  margin: 20px;
  opacity: 0;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 320px;
  }
`;

const shadowAnimation = keyframes`
  0% {
    text-shadow: 0 0 10px #62d5a3, 0 0 20px #62d5a3, 0 0 30px #62d5a3, 0 0 40px #62d5a3;
  }
  50% {
    text-shadow: 0 0 20px #62d5a3, 0 0 30px #62d5a3, 0 0 40px #62d5a3, 0 0 50px #62d5a3;
  }
  100% {
    text-shadow: 0 0 10px #62d5a3, 0 0 20px #62d5a3, 0 0 30px #62d5a3, 0 0 40px #62d5a3;
  }
`;
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
const AnimatedBox = styled(Box)`
  animation: ${slideInFromRight} 1s ease-out forwards;
  padding: 20px;
   border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
`;
// Styled component for the text
const AnimatedText = styled.h1`
  font-size: 55px; // Adjust as needed
  color: white;
  text-align: center;
  width: 100%;
  animation: ${runningLightAnimation} 3s linear infinite;
  margin-bottom: -20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(98, 213, 163, 0.3) 0%, rgba(98, 213, 163, 0) 70%);
    z-index: -1;
  }
`;
const Faucet5: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const stakeAmount = ('20000000000000000000000')
  const LOW_GOD_BALANCE = stakeAmount // 2 Gwei 
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance3 } = useTokenBalance(getCakeAddress())
  const hasLowGod2Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const stakeAmount2 = ('50000000000000000000000')
  const LOW_GOD_BALANCE2 = stakeAmount2 // 2 Gwei
  const { balance: cakeBalance4 } = useTokenBalance(getCakeAddress())
  const hasLowGod3Balance = cakeBalance4.isGreaterThanOrEqualTo(LOW_GOD_BALANCE2)
  const stakeAmount3 = ('100000000000000000000000')
  const LOW_GOD_BALANCE3 = stakeAmount3 // 2 Gwei
  const { balance: cakeBalance5 } = useTokenBalance(getCakeAddress())
  const hasLowGod4Balance = cakeBalance5.isGreaterThanOrEqualTo(LOW_GOD_BALANCE3)
  const { isXs, isSm, isMd, isLg, isXl } = useMatchBreakpoints()

  const isMobile = !isXs
  const isMobile2 = !isSm
  const isMobile3 = !isMd
  const isMobile4 = !isLg
  const isMobile5 = !isXl
   
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const cakeAutoVault = { ...cakePool, isAutoVault: true }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

 
  const all = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL110000')

  const VIZSLAPRICE0 = usePriceCakeBusd()
 
  const VIZSLAPRICE = VIZSLAPRICE0.toNumber()

  const { isVaultApproved, setLastUpdated } = useCheckVaultApprovalStatus()




  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyAllPools = useMemo(
    () =>
      all.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()



  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
          <Flex alignItems="center" justifyContent="space-between" mb="-20px">
          <AnimatedText>VizslaSwap Bonus System</AnimatedText>
      </Flex>
          </Flex>
        </Flex> 
      </PageHeader>
      <Page>
      {!isMobile3 &&
       <Flex justifyContent="center" alignItems="center" flexDirection="column"  mr="-20px" mb="-20px" >
      <ScrollBox3>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K2.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Silver')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 20,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn Daily.')}  
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod2Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>
            </ScrollBox3>
            <ScrollBox2>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K3.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Gold')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 50,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn double rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton  as={NavLink}  to="/airdrops" disabled ={!hasLowGod3Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox2>
            <ScrollBox>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K4.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Ultimate')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 100,000 VIZSLASWAP + 5Q HVI')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn quadruple rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled={!hasLowGod4Balance}  variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox>
            </Flex>
      
                }  
      {!isMobile4 &&  
       <Flex justifyContent="center" alignItems="center"  mr="-20px" mb="-20px" >
      <ScrollBox3>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K2.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Silver')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 20,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn Daily.')}  
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod2Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>
            </ScrollBox3>
            <ScrollBox2>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K3.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Gold')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 50,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn double rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod3Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox2>
            <ScrollBox>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K4.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Ultimate')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 100,000 VIZSLASWAP + 5Q HVI')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn quadruple rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled={!hasLowGod4Balance}  variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox>
            </Flex>
                 }  
      {!isMobile5 && 
       <Flex justifyContent="center" alignItems="center"   mr="-20px" mb="-20px" >
      <ScrollBox3>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K2.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Silver')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 20,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn Daily.')}  
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod2Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>
            </ScrollBox3>
            <ScrollBox2>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K3.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Gold')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 50,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn double rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod3Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox2>
            <ScrollBox>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K4.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Ultimate')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 100,000 VIZSLASWAP + 5Q HVI')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn 4x rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled={!hasLowGod4Balance}  variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox>
            </Flex>
                 }           
 {!isMobile && 
      <Flex justifyContent="center"  flexDirection="column" alignItems="center"  mr="-20px" mb="-20px" >
      <ScrollBox3>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K2.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Silver')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 20,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn Daily.')}  
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod2Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton> 
                    )}
  </Flex>
            </ScrollBox3>
            <ScrollBox2>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K3.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Gold')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 50,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn double rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod3Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox2>
            <ScrollBox>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K4.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Ultimate')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 100,000 VIZSLASWAP + 5Q HVI')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn quadruple rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled={!hasLowGod4Balance}  variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox>
            </Flex>
                }           
{!isMobile2 && 
      <Flex justifyContent="center"  flexDirection="column" alignItems="center"  mr="-20px" mb="-20px" >
      <ScrollBox3>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K2.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Silver')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 20,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn Daily.')}  
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod2Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>
            </ScrollBox3>
            <ScrollBox2>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K3.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Gold')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 50,000 VIZSLASWAP')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn double rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled ={!hasLowGod3Balance} variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>  
            </ScrollBox2>
            <ScrollBox>

<Flex flexDirection="column"   justifyContent="center"  alignItems="center"
>
   <ImageWrapper>
    <img  src="/images/pixel/K4.png"  alt="ifo bunny" width="100px" height="100px"/>
  </ImageWrapper>
  <Text color="background"  bold fontSize="24px" mt="4px">
                {t('Ultimate')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('Hold 100,000 VIZSLASWAP + 5Q HVI')}
              </Text>
              <Text color="text"  bold fontSize="13px" mt="4px">
                {t('and earn quadruple rewards Daily.')} 
              </Text> 
              {!account ? (
              <ConnectWalletButton variant="primary" height="40px" width="100px" mb="5px" mt="10px" />
            ) : (
           <ModernButton as={NavLink}  to="/airdrops" disabled={!hasLowGod4Balance}  variant="tertiary" height="40px" mb="5px" mt="10px" width="100px">
          <ButtonText color="text" bold fontSize="14px">
      {t('JOIN')}
    </ButtonText>
          </ModernButton>
                     )}
  </Flex>   
            </ScrollBox>
            </Flex> 
                }     
                      <Flex justifyContent="center"  alignItems="center"  >
                      <Message variant="warning" mt="50px" mb="50px" >
           <AnimatedBox>     
             <Text fontWeight="bold">{t('You must have at least 100,000 VIZSLASWAP tokens and 5 Quadrillion HVI for the Ultimate package.')}</Text>
             <Text fontWeight="bold">{t('You have 10-25% chance to get double reward in every claim.')}</Text>
  <Text as="p">{t('The highest rewards available with the ultimate package up to:')}</Text>   
  <Link external  href="https://coinmarketcap.com/currencies/bitcoin/" >
 <Text as="p">{t('- 0.00002 BTC tokens')}</Text>   <OpenNewIcon/>       </Link>     
 <Link external href="https://coinmarketcap.com/currencies/vizslaswap/" >
 <Text as="p">{t('- 400 VIZSLASWAP tokens')}</Text>   <OpenNewIcon/>     </Link>
 <Link external href="https://coinmarketcap.com/currencies/hungarian-vizsla-inu/" >
 <Text as="p">{t('- 10,000,000,000,000 HVI tokens')}</Text>  <OpenNewIcon/>        </Link>
 <Link external href="https://coinmarketcap.com/currencies/livegreen-coin/" >
 <Text as="p">{t('- 56,000 LGC tokens')}</Text>   <OpenNewIcon/>     </Link>
 <Link external  href="https://coinmarketcap.com/currencies/baby-vizsla-inu/" >
 <Text as="p">{t('- 28,000,000,000 BABYVIZSLA tokens')}</Text>   <OpenNewIcon/>       </Link>
 <Link external  href="https://coinmarketcap.com/dexscan/bsc/0xc49627586057cd1126edcfb2af6d65b31bc8395b/" >
 <Text as="p">{t('- 8 bethustlers tokens')}</Text>   <OpenNewIcon/>       </Link>
 
           </AnimatedBox> 
         </Message>      
         </Flex>
         </Page>

    </>
  )
}

export default Faucet5
