import React, { useState, useCallback } from 'react'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, useTooltip, HelpIcon, Message, Text, Skeleton, AutoRenewIcon, Spinner } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core/core/core'
import { getaceAddress, getataliraAddress, getbabyvizslaAddress, getbtntyAddress, getbuildAddress,getFarm2Address, getBusdAddress, getbvcAddress, getCakeAddress, getetcAddress, getethAddress, getexzoAddress, getfpsAddress, getHVIAddress, getLAMEAAddress, getlgcAddress, getliraAddress, getltcAddress, gettripAddress, getWbnbAddress, getbabyAddress, getVNAddress, getbtcAddress, getcubyAddress, getFRTCAddress, getHmttAddress } from 'utils/addressHelpers'
import useTokenBalance, { useTotalSupply, useVizslaLPBalance, useGetBnbBalance, useHVILPBalance ,useTotalSupplyVIZSLAHVI, useFarm1,useFarm23, useFarm24, useTotalSupplyFarm24, useFarm22,useTotalSupplyFarm1 ,useTotalSupplyFarm23,useTotalSupplyFarm22, useMaster1 , useFarm2 ,useFarm3,useFarm4, useTotalSupplyFarm2, useTotalSupplyFarm3, useTotalSupplyFarm4, useFarm5, useTotalSupplyFarm5, useFarm6 , useTotalSupplyFarm6 , useFarm7 , useTotalSupplyFarm7 , useFarm8, useTotalSupplyFarm8, useFarm9, useTotalSupplyFarm9 , useFarm10, useTotalSupplyFarm10 , useFarm11, useTotalSupplyFarm11, useFarm12, useFarm13, useFarm14, useFarm15, useFarm16, useFarm17, useFarm18, useFarm19, useFarm20, useFarm21, useTotalSupplyFarm12, useTotalSupplyFarm13, useTotalSupplyFarm14,useTotalSupplyFarm15, useTotalSupplyFarm16, useTotalSupplyFarm17, useTotalSupplyFarm18, useTotalSupplyFarm19, useTotalSupplyFarm20,useTotalSupplyFarm21 } from 'hooks/useTokenBalance'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { getBscScanLink } from 'utils'
import useToast from 'hooks/useToast'
import { updateUserStakedBalance } from 'state/pools'
import { getBalanceNumber, getFullDisplayBalance , getFullDisplayBalance2} from 'utils/formatBalance'
import { useMasterchef } from 'hooks/useContract'
import { harvestFarm } from 'utils/calls'
import Balance from 'components/Balance'
import BigNumber from 'bignumber.js/bignumber'
import useFarmsWithBalance from 'views/Home/hooks/useFarmsWithBalance'
import { usePriceAceBusd, usePriceATALIRABusd, useFarmUser, usePriceBABYVIZSLABusd, usePriceBnbBusd, usePriceBtntyBusd, usePricebuildBusd, usePriceBVCBusd, usePriceCakeBusd, usePriceETCBusd, usePriceETHBusd, usePriceEXZOBusd, usePriceFpsBusd, usePriceHVIBusd , usePriceLameaBusd, usePriceLGCBusd, usePriceliraBusd, usePriceLTCBusd, usePricetripBusd, usePricebabyBusd, usePriceBTCBusd, usePricecubyBusd, usePricefrtcBusd} from 'state/farms/hooks'
import CopyAddress from './CopyAddress'


interface WalletInfoProps {
  hasLowBnbBalance: boolean
  hasLowGodBalance: boolean
  hasLowGod2Balance: boolean
  hasLowGod3Balance: boolean
  hasLowGod4Balance: boolean
  hasLowGod5Balance: boolean
  hasLowGod6Balance: boolean
  hasLowGod7Balance: boolean
  hasLowGod8Balance: boolean
  hasLowGod9Balance: boolean
  hasLowGod10Balance: boolean
  hasLowGodBalance11: boolean
  hasLowGodBalance12: boolean
  hasLowGodBalance13: boolean
  hasLowGodBalance14: boolean
  hasLowGodBalance15: boolean
  hasLowGodBalance16: boolean
  hasLowGodBalance17: boolean
  hasLowGodBalance18: boolean
  hasLowGodBalance19: boolean
  hasLowGodBalance20: boolean
  hasLowGodBalance21: boolean
  hasLowGodBalance22: boolean
  hasLowGodBalance23: boolean
  hasLowGodBalance24: boolean
  hasLowGodBalance25: boolean
  hasLowGodBalanceN: boolean
  hasLowGodBalance1X: boolean 
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance: cakeBalanceN } = useTokenBalance(getVNAddress())
 
  
  const balanceNt = cakeBalanceN.toNumber()
  const price0 = usePriceCakeBusd()
  const price = price0.toNumber()
  const { logout } = useAuth()
  const { farmsWithStakedBalance, earningsSum: farmEarningsSum } = useFarmsWithBalance()


  const cakePriceBusd = usePriceCakeBusd()
  const earningsBusd = new BigNumber(farmEarningsSum).multipliedBy(cakePriceBusd)
  const numFarmsToCollect = farmsWithStakedBalance.length


  const { targetRef, tooltip, tooltipVisible } = useTooltip(t('You can view just Active VizslaSwap Tokens in your wallet.'), {
    placement: 'bottom',
  })
 
  const earningsText = t('%earningsBusd% Collect from %count% %farms%', {
    earningsBusd: earningsBusd.toString(),
    count: numFarmsToCollect > 0 ? numFarmsToCollect : '',
    farms: numFarmsToCollect === 0 || numFarmsToCollect > 1 ? 'farms' : 'farm',
  })
  const [preText, toCollectText] = earningsText.split(earningsBusd.toString())
  const earningsBusd2 = earningsBusd.div(cakePriceBusd)
  


  const handleLogout = () => {
    onDismiss()
    logout()
  }


  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowBnbBalance && (
           <Message variant="danger" mb="24px">
           <Box>
             <Text fontWeight="bold">{t('BNB Balance Low')}</Text>
             <Text as="p">{t('You need BNB for transaction fees.')}</Text>
           </Box>
         </Message>
      )}
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
      {tooltipVisible && tooltip}
    </>
  )
}

export default WalletInfo
