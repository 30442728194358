import { Button,Box,Text } from "@pancakeswap/uikit";
import styled, { keyframes } from "styled-components";

const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  50% {
    text-shadow: 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3, 0 0 12.5px #62d5a3;
  }
  100% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
`;

const Title = styled(Text)`
  text-align: center;
  color: #fff;
  font-size: 50px;
  font-weight: bold; /* Make the text thicker */
  margin-bottom: 20px;
    margin-top: 40px;
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
`;

const ChatBox = styled(Box)`
  flex: 1;
  overflow-y: auto;
  height: 400px;
  max-height: 400px;
  min-height: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    max-height: 300px;
    min-height: 300px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

const Sidebar = styled.div`
  width: 200px;
  padding: 10px;
  border-right: 1px solid #ccc;
  max-height: 400px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ccc;
    max-height: 300px;
  }
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 100%;
  overflow-y: auto;
`;

interface UserListItemProps {
  isActive: boolean;
  isTop10: boolean;
}

const UserListItem = styled.li<UserListItemProps>`
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  color: ${({ isTop10 }) => (isTop10 ? '#d4bf79' : '#fff')};

  &:hover {
    background-color: #555;
  }

  &:active {
    background-color: #fff;
  }

  ${({ isActive }) => isActive && `
    background-color: #555;
  `}
`;

const UserButton = styled(Button)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  text-align: left;
  width: 97%;
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 25%;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  background-color: #000;
  color: #fff;
`;

const Message = styled(Text)`
  flex: 1;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
  width: 100%;
  transition: transform 0.2s;
`;

const SendButton = styled(Button)`
  width: 100%;
  transition: transform 0.2s;
`;

const Timestamp = styled.span`
  display: block;
  font-size: 0.8em;
  color: #aaa;
`;

export { Container, ChatBox, Sidebar,UserList, UserListItem, UserButton, Avatar, MessageContainer, Message, Input, SendButton, Timestamp, Title };
