import React, { useMemo, useState } from 'react'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import styled from 'styled-components'
import { Modal, Text, Flex, Button, HelpIcon, AutoRenewIcon, useTooltip } from '@pancakeswap/uikit'
import { getBalanceNumber } from 'utils/formatBalance'

import { useTranslation } from 'contexts/Localization'
import { usePriceCakeBusd , usePriceHVIBusd, usePriceFpsBusd, usePollFarmsData,useFarms, usePriceLGCBusd, usePriceBnbBusd,usePriceliraBusd, usePriceLameaBusd, usePriceBVCBusd, usePriceBABYVIZSLABusd, usePriceETCBusd, usePriceATALIRABusd, usePriceEXZOBusd, usePriceskeletonBusd, usePricetripBusd, usePricebuildBusd, usePricebabyBusd, usePriceLTCBusd } from 'state/farms/hooks'
import { getAddress, getHVIAddress , getCakeAddress, getWbnbAddress ,getFarm24Address, getFarm1Address, getlgcAddress,getFarm23Address, getFarm22Address, geLiraAddress, getFarm2Address, gettripAddress,getFarm4Address,getFarm5Address, getBusdAddress, getFarm3Address, getFarm6Address , getFarm7Address, getLAMEAAddress, getFarm8Address, getbvcAddress, getFarm9Address, getbabyvizslaAddress, getFarm10Address, getfpsAddress, getFarm11Address, getskeletonAddress, getFarm12Address, getFarm13Address, getFarm14Address , getFarm15Address, getFarm16Address , getFarm17Address, getFarm18Address, getFarm19Address, getFarm20Address, getFarm21Address, getbuildAddress, getethAddress, getataliraAddress, getexzoAddress, getbabyAddress, getFarm26Address, getFarm27Address, getetcAddress, getltcAddress} from 'utils/addressHelpers'
import { useTotalSupply, useVizslaLPBalance, useHVILPBalance ,useTotalSupplyVIZSLAHVI, useFarm1,useFarm23, useFarm24, useTotalSupplyFarm24, useFarm22,useTotalSupplyFarm1 ,useTotalSupplyFarm23,useTotalSupplyFarm22, useMaster1 , useFarm2 ,useFarm3,useFarm4, useTotalSupplyFarm2, useTotalSupplyFarm3, useTotalSupplyFarm4, useFarm5, useTotalSupplyFarm5, useFarm6 , useTotalSupplyFarm6 , useFarm7 , useTotalSupplyFarm7 , useFarm8, useTotalSupplyFarm8, useFarm9, useTotalSupplyFarm9 , useFarm10, useTotalSupplyFarm10 , useFarm11, useTotalSupplyFarm11, useFarm12, useFarm13, useFarm14, useFarm15, useFarm16, useFarm17, useFarm18, useFarm19, useFarm20, useFarm21, useTotalSupplyFarm12, useTotalSupplyFarm13, useTotalSupplyFarm14,useTotalSupplyFarm15, useTotalSupplyFarm16, useTotalSupplyFarm17, useTotalSupplyFarm18, useTotalSupplyFarm19, useTotalSupplyFarm20,useTotalSupplyFarm21, useFarm27, useFarm26, useTotalSupplyFarm26, useTotalSupplyFarm27, usePool5, usePool4, usePool9, usePool8, usePool7, usePool6, usePool10 , usePool11, usePool12, usePool13, usePool14, usePool15, usePool16, usePool17, usePool18, usePool19, usePool20 , usePool21, usePool22, usePool23, usePool24, usePool25, usePool26, usePool27, usePool28, usePool29, usePool30, usePool31, usePool32, usePool33, usePool34, usePool35, usePool36, usePool37 , usePool38, usePool40, usePool41, usePool39, usePool42, usePool43, usePool44, usePool45, usePool46, usePool47, usePool1, usePool2, usePool3, usePool4a, usePool4b, usePool4c, usePool4d} from 'hooks/useTokenBalance'
import Balance from 'components/Balance'

interface ModalInfoProps {
  onDismiss?: () => void
  TooltipComponent: React.ElementType
}

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  height: 1px;
  margin: 5px auto;
  width: 100%;
`

const ModalInfo: React.FC<ModalInfoProps> = ({ onDismiss, TooltipComponent }) => {
  const { t } = useTranslation()


  const bnbprice0 = usePriceBnbBusd()
  const bnbprice = bnbprice0.toNumber()
  const cakePriceBusd = usePriceCakeBusd()
  const vizslaprice = cakePriceBusd.toNumber()
  const hviprice0 = usePriceHVIBusd()
  const hviprice = hviprice0.toNumber()
  const lgcprice0 = usePriceLGCBusd()
  const lgcprice = lgcprice0.toNumber()
  const lameaprice0 = usePriceLameaBusd()
  const lameaprice = lameaprice0.toNumber()
  const bvcprice0 = usePriceBVCBusd()
  const bvcprice = bvcprice0.toNumber()
  const babyvizslaprice0 = usePriceBABYVIZSLABusd()
  const babyvizslaprice = babyvizslaprice0.toNumber()
  const fpsprice0 = usePriceFpsBusd()
  const fpsprice = fpsprice0.toNumber()
  const skeletonprice0 = usePriceskeletonBusd()
  const skeletonprice = skeletonprice0.toNumber()
  const ethprice0 = usePriceETCBusd()
  const ehtprice = ethprice0.toNumber()
  const buildprice0 = usePricebuildBusd()
  const buildprice = buildprice0.toNumber()
  const ataliraprice0 = usePriceATALIRABusd()
  const ataliraprice = ataliraprice0.toNumber()
  const exzoprice0 = usePriceEXZOBusd()
  const exzoprice = exzoprice0.toNumber()
  const tripprice0 = usePricetripBusd()
  const tripprice = tripprice0.toNumber()
  const liraprice0 = usePriceliraBusd()
  const liraprice = liraprice0.toNumber()
  const babyprice0 = usePricebabyBusd()
  const babyprice = babyprice0.toNumber()
  const ltcprice0 = usePriceLTCBusd()
  const ltcprice = ltcprice0.toNumber()
  const etcprice0 = usePriceETCBusd()
  const etcprice = etcprice0.toNumber()
  // farm 1
    
    const vizslalpbalance = getBalanceNumber(useFarm1(getCakeAddress()))
    const bnblpbalance = getBalanceNumber(useFarm1(getWbnbAddress()))
  
    const kombo1 = useTotalSupplyFarm1()
    const kombo2 = kombo1 ? getBalanceNumber(kombo1) : 0
  
    const teljes = vizslaprice * vizslalpbalance 
    const teljes2 = bnbprice * bnblpbalance
    const teljes3 = teljes + teljes2
    const minden = teljes3 / kombo2  
    
    const masterfarm1 = getBalanceNumber(useMaster1(getFarm1Address()))
  
    const farm1 = minden * masterfarm1
  
    // farm 2 
    const vizslalpbalance2 = getBalanceNumber(useFarm2(getCakeAddress()))
    const bnblpbalance2 = getBalanceNumber(useFarm2(getWbnbAddress()))
  
    const kombo3 = useTotalSupplyFarm2()
    const kombo4 = kombo3 ? getBalanceNumber(kombo3) : 0
  
    const teljes4 = vizslaprice * vizslalpbalance2 
    const teljes5 = bnbprice * bnblpbalance2
    const teljes6 = teljes4 + teljes5
    const minden1 = teljes6 / kombo4
  
    const masterfarm2 = getBalanceNumber(useMaster1(getFarm2Address()))
  
    const farm2 = minden1 * masterfarm2
  // farm 3
    const vizslalpbalance3 = getBalanceNumber(useFarm3(getCakeAddress()))
    const busdlpbalance = getBalanceNumber(useFarm3(getBusdAddress()))
  
    const kombo5 = useTotalSupplyFarm3()
    const kombo6 = kombo5 ? getBalanceNumber(kombo5) : 0
   
    const teljes7 = vizslaprice * vizslalpbalance3 
    const teljes8 = 1 * busdlpbalance
    const teljes9 = teljes7 + teljes8
    const minden2 = teljes9 / kombo6
  
    const masterfarm3 = getBalanceNumber(useMaster1(getFarm3Address()))
  
    const farm3 = minden2 * masterfarm3
    // farm 4
    const vizslalpbalance4 = getBalanceNumber(useFarm4(getCakeAddress()))
    const busdlpbalance2 = getBalanceNumber(useFarm4(getBusdAddress()))
  
    const kombo7 = useTotalSupplyFarm4()
    const kombo8 = kombo7 ? getBalanceNumber(kombo7) : 0
  
    const teljes10 = vizslaprice * vizslalpbalance4
    const teljes11 = 1 * busdlpbalance2
    const teljes12 = teljes10 + teljes11
    const minden3 = teljes12 / kombo8
  
    const masterfarm4 = getBalanceNumber(useMaster1(getFarm4Address()))
  
    const farm4 = minden3 * masterfarm4
      // farm 5
      const hvilpbalance = getBalanceNumber(useFarm5(getHVIAddress()))
      const bnblpbalance3 = getBalanceNumber(useFarm5(getWbnbAddress()))
  
      const kombo9 = useTotalSupplyFarm5()
      const kombo10 = kombo9 ? getBalanceNumber(kombo9) : 0
  
      const teljes13 = hviprice * hvilpbalance
      const teljes14 = bnbprice * bnblpbalance3
      const teljes15 = teljes13 + teljes14
      const minden4 = teljes15 / kombo10
  
      const masterfarm5 = getBalanceNumber(useMaster1(getFarm5Address()))
  
      const farm5 = minden4 * masterfarm5 * 2
      // farm 6
      const hvilpbalance2 = getBalanceNumber(useFarm6(getHVIAddress()))
      const busdlpbalance3 = getBalanceNumber(useFarm6(getBusdAddress()))
  
      
      const kombo11 = useTotalSupplyFarm6()
      const kombo12 = kombo11 ? getBalanceNumber(kombo11) : 0
  
      const teljes16 = hviprice * hvilpbalance2
      const teljes17 = 1 * busdlpbalance3
      const teljes18 = teljes16 + teljes17
      const minden5 = teljes18 / kombo12
  
      const masterfarm6 = getBalanceNumber(useMaster1(getFarm6Address()))
  
      const farm6 = minden5 * masterfarm6 * 2
  // farm 7
      const hvilpbalance3 = getBalanceNumber(useFarm7(getHVIAddress()))
      const vizslalpbalance5 = getBalanceNumber(useFarm7(getCakeAddress()))
  
      const kombo13 = useTotalSupplyFarm7()
      const kombo14 = kombo13 ? getBalanceNumber(kombo13) : 0
  
      const teljes19 = hviprice * hvilpbalance3
      const teljes20 = vizslaprice * vizslalpbalance5
      const teljes21 = teljes19 + teljes20
      const minden6 = teljes21 / kombo14
  
      const masterfarm7 = getBalanceNumber(useMaster1(getFarm7Address()))
  
      const farm7 = minden6 * masterfarm7 * 2
  // farm 8
  const lamealpbalance = getBalanceNumber(useFarm8(getLAMEAAddress()))
  const vizslalpbalance6 = getBalanceNumber(useFarm8(getCakeAddress()))
  
  const kombo15 = useTotalSupplyFarm8()
  const kombo16 = kombo15 ? getBalanceNumber(kombo15) : 0
  
  const teljes22 = lameaprice * lamealpbalance
  const teljes23 = vizslaprice * vizslalpbalance6
  const teljes24 = teljes22 + teljes23
  const minden7 = teljes24 / kombo16
  
  const masterfarm8 = getBalanceNumber(useMaster1(getFarm8Address()))
  
  const farm8 = minden7 * masterfarm8 
  // farm 9
  const bvclpbalance = getBalanceNumber(useFarm9(getbvcAddress()))
  const vizslalpbalance7 = getBalanceNumber(useFarm9(getCakeAddress()))
  
  const kombo17 = useTotalSupplyFarm9()
  const kombo18 = kombo17 ? getBalanceNumber(kombo17) : 0
  
  const teljes25 = bvcprice * bvclpbalance
  const teljes26 = vizslaprice * vizslalpbalance7
  const teljes27 = teljes25 + teljes26
  const minden8 = teljes27 / kombo18
  
  const masterfarm9 = getBalanceNumber(useMaster1(getFarm9Address()))
  
  const farm9 = minden8 * masterfarm9
  // farm 10
  const babylpbalance = getBalanceNumber(useFarm10(getbabyvizslaAddress()))
  const vizslalpbalance8 = getBalanceNumber(useFarm10(getCakeAddress()))
  
  const kombo19 = useTotalSupplyFarm10()
  const kombo20 = kombo19 ? getBalanceNumber(kombo19) : 0
  
  const teljes28 = babyvizslaprice * babylpbalance
  const teljes29 = vizslaprice * vizslalpbalance8
  const teljes30 = teljes28 + teljes29
  const minden9 = teljes30 / kombo20
  
  const masterfarm10 = getBalanceNumber(useMaster1(getFarm10Address()))
  
  const farm10 = minden9 * masterfarm10
  // farm 11
  const vizslalpbalance9 = getBalanceNumber(useFarm11(getCakeAddress()))
  const fpslpbalance = getBalanceNumber(useFarm11(getfpsAddress()))
  
  const kombo21 = useTotalSupplyFarm11()
  const kombo22 = kombo21 ? getBalanceNumber(kombo21) : 0
  
  const teljes31 = fpsprice * fpslpbalance
  const teljes32 = vizslaprice * vizslalpbalance9
  const teljes33 = teljes31 + teljes32
  const minden10 = teljes33 / kombo22
  
  const masterfarm11 = getBalanceNumber(useMaster1(getFarm11Address()))
  
  const farm11 = minden10 * masterfarm11
  
  // farm 12
  const vizslalpbalance10 = getBalanceNumber(useFarm12(getCakeAddress()))
  const skeletonlpbalance = getBalanceNumber(useFarm12(getskeletonAddress()))
  
  const kombo23 = useTotalSupplyFarm12()
  const kombo24 = kombo23 ? getBalanceNumber(kombo23) : 0
  
  const teljes34 = skeletonprice * skeletonlpbalance
  const teljes35 = vizslaprice * vizslalpbalance10
  const teljes36 = teljes34 + teljes35
  const minden11 = teljes36 / kombo24
  
  const masterfarm12 = getBalanceNumber(useMaster1(getFarm12Address()))
  
  const farm12 = minden11 * masterfarm12
  
  // farm 13
  const vizslalpbalance11 = getBalanceNumber(useFarm13(getCakeAddress()))
  const buildlpbalance = getBalanceNumber(useFarm13(getbuildAddress()))
  
  const kombo25 = useTotalSupplyFarm13()
  const kombo26 = kombo25 ? getBalanceNumber(kombo25) : 0
  
  const teljes37 = buildprice * buildlpbalance
  const teljes38 = vizslaprice * vizslalpbalance11
  const teljes39 = teljes37 + teljes38
  const minden12 = teljes39 / kombo26
  
  const masterfarm13 = getBalanceNumber(useMaster1(getFarm13Address()))
  
  const farm13 = minden12 * masterfarm13
  
  // farm 14
  const vizslalpbalance12 = getBalanceNumber(useFarm14(getCakeAddress()))
  const ethlpbalance = getBalanceNumber(useFarm14(getethAddress()))
  
  const kombo27 = useTotalSupplyFarm14()
  const kombo28 = kombo27 ? getBalanceNumber(kombo27) : 0
  
  const teljes40 = ehtprice * ethlpbalance
  const teljes41 = vizslaprice * vizslalpbalance12
  const teljes42 = teljes40 + teljes41
  const minden13 = teljes42 / kombo28 * 2
  
  const masterfarm14 = getBalanceNumber(useMaster1(getFarm14Address()))
  
  const farm14 = minden13 * masterfarm14
  
  // farm 15
  const vizslalpbalance13 = getBalanceNumber(useFarm15(getCakeAddress()))
  const ataliralpbalance = getBalanceNumber(useFarm15(getataliraAddress()))
  
  const kombo29 = useTotalSupplyFarm15()
  const kombo30 = kombo29 ? getBalanceNumber(kombo29) : 0
  
  const teljes43 = ataliraprice * ataliralpbalance
  const teljes44 = vizslaprice * vizslalpbalance13
  const teljes45 = teljes43 + teljes44
  const minden14 = teljes45 / kombo30 
  
  const masterfarm15 = getBalanceNumber(useMaster1(getFarm15Address()))
  
  const farm15 = minden14 * masterfarm15
  
  // farm 16
  const vizslalpbalance16 = getBalanceNumber(useFarm16(getCakeAddress()))
  const exzolpbalance = getBalanceNumber(useFarm16(getexzoAddress()))
  
  const kombo31 = useTotalSupplyFarm16()
  const kombo32 = kombo31 ? getBalanceNumber(kombo31) : 0
  
  const teljes46 = exzoprice * exzolpbalance
  const teljes47 = vizslaprice * vizslalpbalance16
  const teljes48 = teljes46 + teljes47
  const minden15 = teljes48 / kombo32 * 2
  
  const masterfarm16 = getBalanceNumber(useMaster1(getFarm16Address()))
  
  const farm16 = minden15 * masterfarm16
  
  // farm 17
  const bnblpbalance4 = getBalanceNumber(useFarm17(getWbnbAddress()))
  const lamealpbalance2 = getBalanceNumber(useFarm17(getLAMEAAddress()))
  
  const kombo33 = useTotalSupplyFarm17()
  const kombo34 = kombo33 ? getBalanceNumber(kombo33) : 0
  
  const teljes49 = lameaprice * lamealpbalance2
  const teljes50 = bnbprice * bnblpbalance4
  const teljes51 = teljes49 + teljes50
  const minden16 = teljes51 / kombo34
  
  const masterfarm17 = getBalanceNumber(useMaster1(getFarm17Address()))
  
  const farm17 = minden16 * masterfarm17
  
  // farm 18
  const bnblpbalance5 = getBalanceNumber(useFarm18(getWbnbAddress()))
  const bvclpbalance2 = getBalanceNumber(useFarm18(getbvcAddress()))
  
  const kombo35 = useTotalSupplyFarm18()
  const kombo36 = kombo35 ? getBalanceNumber(kombo35) : 0
  
  const teljes52 = bvcprice * bvclpbalance2
  const teljes53 = bnbprice * bnblpbalance5
  const teljes54 = teljes52 + teljes53
  const minden17 = teljes54 / kombo36
  
  const masterfarm18 = getBalanceNumber(useMaster1(getFarm18Address()))
  
  const farm18 = minden17 * masterfarm18
  
  // farm 19
  const bnblpbalance6 = getBalanceNumber(useFarm19(getWbnbAddress()))
  const babyvizslalpbalance = getBalanceNumber(useFarm19(getbabyvizslaAddress()))
  
  const kombo37 = useTotalSupplyFarm19()
  const kombo38 = kombo37 ? getBalanceNumber(kombo37) : 0
  
  const teljes55 = babyvizslaprice * babyvizslalpbalance
  const teljes56 = bnbprice * bnblpbalance6
  const teljes57 = teljes55 + teljes56
  const minden18 = teljes57 / kombo38
  
  const masterfarm19 = getBalanceNumber(useMaster1(getFarm19Address()))
  
  const farm19 = minden18 * masterfarm19
  
  // farm 20
  const vizslalpbalance17 = getBalanceNumber(useFarm20(getCakeAddress()))
  const triplpbalance = getBalanceNumber(useFarm20(gettripAddress()))
  
  const kombo39 = useTotalSupplyFarm20()
  const kombo40 = kombo39 ? getBalanceNumber(kombo39) : 0
  
  const teljes58 = tripprice * triplpbalance
  const teljes59 = vizslaprice * vizslalpbalance17
  const teljes60 = teljes58 + teljes59
  const minden19 = teljes60 / kombo40
  
  const masterfarm20 = getBalanceNumber(useMaster1(getFarm20Address()))
  
  const farm20 = minden19 * masterfarm20
  
  // farm 21
  const vizslalpbalance18 = getBalanceNumber(useFarm22(getCakeAddress()))
  const liralpbalance = getBalanceNumber(useFarm22(geLiraAddress()))
  
  const kombo43 = useTotalSupplyFarm22()
  const kombo44 = kombo43 ? getBalanceNumber(kombo43) : 0
  
  const teljes64 = vizslaprice * vizslalpbalance18
  const teljes65 = liraprice * liralpbalance
  const teljes66 = teljes64 + teljes65
  const minden21 = teljes66 / kombo44
  
  const masterfarm22 = getBalanceNumber(useMaster1(getFarm22Address()))
  
  const farm22 = minden21 * masterfarm22
  
  // farm 22
  const vizslalpbalance19 = getBalanceNumber(useFarm23(getCakeAddress()))
  const lgclpbalance = getBalanceNumber(useFarm23(getlgcAddress()))
  
  const kombo45 = useTotalSupplyFarm23()
  const kombo46 = kombo45 ? getBalanceNumber(kombo45) : 0
  
  const teljes67 = vizslaprice * vizslalpbalance19
  const teljes68 = lgcprice * lgclpbalance
  const teljes69 = teljes67 + teljes68
  const minden22 = teljes69 / kombo46
  
  const masterfarm23 = getBalanceNumber(useMaster1(getFarm23Address()))
  
  const farm23 = minden22 * masterfarm23
  
  // farm 23
  const bnblpbalance7 = getBalanceNumber(useFarm24(getWbnbAddress()))
  const lgclpbalance2 = getBalanceNumber(useFarm24(getlgcAddress()))
  
  const kombo47 = useTotalSupplyFarm24()
  const kombo48 = kombo47 ? getBalanceNumber(kombo47) : 0
  
  const teljes70 = bnbprice * bnblpbalance7
  const teljes71 = lgcprice * lgclpbalance2
  const teljes72 = teljes70 + teljes71
  const minden23 = teljes72 / kombo48
  
  const masterfarm24 = getBalanceNumber(useMaster1(getFarm24Address()))
  
  const farm24 = minden23 * masterfarm24

  // farm 24
const bnblpbalance8 = getBalanceNumber(useFarm26(getWbnbAddress()))
const babybalance = getBalanceNumber(useFarm26(getbabyAddress()))

const kombo49 = useTotalSupplyFarm26()
const kombo50 = kombo49 ? getBalanceNumber(kombo49) : 0

const teljes73 = bnbprice * bnblpbalance8
const teljes74 = babyprice * babybalance
const teljes75 = teljes73 + teljes74
const minden24 = teljes75 / kombo50

const masterfarm25 = getBalanceNumber(useMaster1(getFarm26Address()))

const farm25 = minden24 * masterfarm25

// farm 25
const vizslalpbalance20 = getBalanceNumber(useFarm27(getCakeAddress()))
const babybalance2 = getBalanceNumber(useFarm27(getbabyAddress()))

const kombo51 = useTotalSupplyFarm27()
const kombo52 = kombo51 ? getBalanceNumber(kombo51) : 0

const teljes76 = vizslaprice * vizslalpbalance20
const teljes77 = babyprice * babybalance2
const teljes78 = teljes76 + teljes77
const minden25 = teljes78 / kombo52

const masterfarm26 = getBalanceNumber(useMaster1(getFarm27Address()))

const farm26 = minden25 * masterfarm26
  
  // minden farm 
  
   const farmminden =  farm1 + farm2 + farm3 + farm4 + farm5 + farm6 + farm7 + farm8 + farm9 + farm10 + farm11 + farm12 + farm13 + farm14 + farm15 + farm16 + farm17 + farm18 + farm19 + farm20  + farm22 + farm23 + farm24
  
   const POOL4 = getBalanceNumber(usePool4(getCakeAddress()))
   const POOL5 = getBalanceNumber(usePool5(getCakeAddress()))
   const POOL6 = getBalanceNumber(usePool6(getCakeAddress()))
   const POOL7 = getBalanceNumber(usePool7(getCakeAddress()))
   const POOL8 = getBalanceNumber(usePool8(getCakeAddress()))
   const POOL9 = getBalanceNumber(usePool9(getCakeAddress()))
   const POOL10 = getBalanceNumber(usePool10(getCakeAddress()))
   const POOL11 = getBalanceNumber(usePool11(getCakeAddress()))
   const POOL12 = getBalanceNumber(usePool12(getCakeAddress()))
   const POOL13 = getBalanceNumber(usePool13(getCakeAddress()))
   const POOL14 = getBalanceNumber(usePool14(getCakeAddress()))
   const POOL15 = getBalanceNumber(usePool15(getCakeAddress()))
   const POOL16 = getBalanceNumber(usePool16(getCakeAddress()))
   const POOL17 = getBalanceNumber(usePool17(getCakeAddress()))
   const POOL18 = getBalanceNumber(usePool18(getCakeAddress()))
   const POOL19 = getBalanceNumber(usePool19(getCakeAddress()))
   const POOL20 = getBalanceNumber(usePool20(getCakeAddress()))
   const POOL21 = getBalanceNumber(usePool21(getCakeAddress()))
   const POOL22 = getBalanceNumber(usePool22(getHVIAddress()))
   const POOL23 = getBalanceNumber(usePool23(getHVIAddress()))
   const POOL24 = getBalanceNumber(usePool24(getHVIAddress()))
   const POOL25 = getBalanceNumber(usePool25(getHVIAddress()))
   const POOL26 = getBalanceNumber(usePool26(getHVIAddress()))
   const POOL27 = getBalanceNumber(usePool27(getHVIAddress()))
   const POOL28 = getBalanceNumber(usePool28(getHVIAddress()))
   const POOL29 = getBalanceNumber(usePool29(getHVIAddress()))
   const POOL30 = getBalanceNumber(usePool30(getWbnbAddress()))
   const POOL31 = getBalanceNumber(usePool31(getethAddress()))
   const POOL32 = getBalanceNumber(usePool32(getetcAddress()))
   const POOL33 = getBalanceNumber(usePool33(getltcAddress()))
   const POOL34 = getBalanceNumber(usePool34(getLAMEAAddress()))
   const POOL35 = getBalanceNumber(usePool35(getataliraAddress()))
   const POOL36 = getBalanceNumber(usePool36(getexzoAddress()))
   const POOL37 = getBalanceNumber(usePool37(getbvcAddress()))
   const POOL38 = getBalanceNumber(usePool38(getbabyvizslaAddress()))
   const POOL39 = getBalanceNumber(usePool39(geLiraAddress()))
   const POOL40 = getBalanceNumber(usePool40(getCakeAddress()))
   const POOL41 = getBalanceNumber(usePool41(getHVIAddress()))
   const POOL42 = getBalanceNumber(usePool42(getlgcAddress()))
   const POOL43 = getBalanceNumber(usePool43(getCakeAddress()))
   const POOL44 = getBalanceNumber(usePool44(getHVIAddress()))
   const POOL45 = getBalanceNumber(usePool45(getbabyAddress()))
   const POOL46 = getBalanceNumber(usePool46(getCakeAddress()))
   const POOL47 = getBalanceNumber(usePool47(getHVIAddress()))
 
   const MAJDNEM  = POOL4 + POOL5 + POOL6 + POOL7 + POOL8 + POOL9 + POOL10 + POOL11 + POOL12 + POOL13 + POOL14 + POOL15 + POOL16 + POOL17 + POOL18 + POOL19 + POOL20 + POOL21  + POOL40 + POOL43 + POOL46
   const MAJDNEM2 = POOL22 + POOL23 + POOL24 + POOL25 + POOL26 + POOL27 + POOL28 + POOL29 + POOL41 + POOL44 + POOL47
   const FULL1 = MAJDNEM * vizslaprice
   const FULL2 = MAJDNEM2 * hviprice * 1000000000
   const FULL4 = POOL30 * bnbprice
   const FULL5 = POOL31 * ehtprice
   const FULL6 = POOL32 * etcprice
   const FULL7 = POOL33 * ltcprice
   const FULL8 = POOL34 * lameaprice
   const FULL9 = POOL35 * ataliraprice * 1000000000
   const FULL10 = POOL36 * exzoprice * 1000000000
   const FULL11 = POOL37 * bvcprice
   const FULL12 = POOL38 * babyvizslaprice
   const FULL13 = POOL39 * liraprice
   const FULL14 = POOL42 * lgcprice
   const FULL15 = POOL45 * babyprice
   const FULL3 = FULL1 + FULL2  + FULL4 + FULL5 + FULL6 + FULL7 + FULL8 + FULL9 + FULL10 + FULL11 + FULL12 + FULL13 + FULL14 + FULL15

   const POOL1 = getBalanceNumber(usePool1(getCakeAddress()))
   const POOL2 = getBalanceNumber(usePool2(getCakeAddress()))
   const POOL3 = getBalanceNumber(usePool3(getCakeAddress()))
   const POOL4a = getBalanceNumber(usePool4a(getCakeAddress()))
   const POOL4b = getBalanceNumber(usePool4b(getCakeAddress()))
   const POOL4c = getBalanceNumber(usePool4c(getCakeAddress()))
   const POOL4d = getBalanceNumber(usePool4d(getCakeAddress()))
   const VÉGE = POOL1 + POOL2 + POOL3 + POOL4a + POOL4b + POOL4c + POOL4d
   const VÉGE1 = VÉGE * vizslaprice
 
   const allin = VÉGE1 + FULL3 + farmminden


  return (
    <Modal title={t('About LiveGreen Coin')} onDismiss={onDismiss}>

          <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('LiveGreen Coin is european initiative to reform the crypto currency market with special emphasis on environmental and green aspects.Behind the project are HAPPY PANDA Handels GmbH, founded in Austria, and the international companies attached to that, what’s more, the token even functions as a share in HAPPY PANDA Handels GmbH. In addition, it is based on a tender ecosystem, fron which the non-profit and for-profit tenders can benefit, driven partly by investors (DAO = Decentralized Autonomous Organization) and judged partly by the management of LGC. Aim to create a social platform that is a combination of a commercial platform (marketplace) and a social profile. This is a crypto - friendly platform, where ads are filtered through pre-inspection to prevent many unpleasant abuses. We intend to create a platform where the user can actually feel safe, and, of course, the LGC Token will be the basis of this.')} </Text>
      </Flex>

    </Modal>
  )
}

export default ModalInfo
