import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core/core/core'
import { Button, Flex} from '@pancakeswap/uikit'
import { ethers } from 'ethers';
import styled, { keyframes } from 'styled-components'
import { getliraAddress } from 'utils/addressHelpers'
import useTokenBalance,{ useGetBnbBalance } from 'hooks/useTokenBalance'
import { usePriceHVIBusd } from 'state/farms/hooks';
import Page from 'components/Layout/Page'
import FarmsPoolsRowLoader from '../Home/components/FarmsPoolsRowLoader'

const StyledButton = styled(Button)`
  width: 200px;
  margin: auto;
  &:hover {
    background-color: #f6c944;
    color: black;
  }
`

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Stílus komponens
const ImageWrapper = styled.div`
  margin-top: -20px;
  animation: ${slideInFromBottom} 1s ease-out forwards;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: -20px;
	  animation: ${slideInFromBottom} 1s ease-out forwards;
  }
`;
const grow = keyframes`
0% {
  transform: scale(0.5);
  opacity: 0;
}
100% {
  transform: scale(1);
  opacity: 1;
}
`;
const AnimatedHeading = styled.h2`
  font-size: 2em;
  color: ${({ color }) => color || '#ffffff'};
  text-align: center;
  animation: ${grow} 0.5s ease-out forwards;
`;

// ABI of the new contract
const newContractAbi = [
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "tokenAddress",
		  "type": "address"
		}
	  ],
	  "stateMutability": "payable",
	  "type": "constructor"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "Deposit",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "harvest",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "newRewardAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "setRewardAmount",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "Withdrawal",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "withdrawToken",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "stateMutability": "payable",
	  "type": "receive"
	},
	{
	  "inputs": [],
	  "name": "getBalance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "lastUserActionTime",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "rewardAmount",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "token",
	  "outputs": [
		{
		  "internalType": "contract IERC20",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "userInfo",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "lastUserActionTime",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "shares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "lastDepositedTime",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "pendingReward",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	}
  ];
// ABI of the contract
const abi = [
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_tokenAddress2",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "lockTime",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "initialRewardAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "rewardIncrement",
				"type": "uint256"
			}
		],
		"name": "ParametersUpdated",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_lockTime",
				"type": "uint256"
			}
		],
		"name": "setTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenAddress",
				"type": "address"
			}
		],
		"name": "setToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_lockTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_initialRewardAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_rewardIncrement",
				"type": "uint256"
			}
		],
		"name": "updateParameters",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DEFAULT_LOCK_TIME",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getAvailableReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLastTenUsers",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getUserClaim",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"internalType": "struct Faucet.UserClaim",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "INITIAL_REWARD_AMOUNT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lastIncrementTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "lastTenUsers",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "lastUserActionTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minToken",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "REWARD_INCREMENT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TIME_INCREMENT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token2",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userClaims",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]
const contractAddress = '0xf1862DB540C3E0A473b4Fff2e828474260B79f11';
const newContractAddress = '0xb191403C9fE54a96584a6abF703651ad81E80817';

function VizslaSwapFaucet() {
    const { account } = useWeb3React();
    const cakePriceBusd = usePriceHVIBusd();
    const hvprice = cakePriceBusd.toNumber();
	const stakeAmount = ('1000000000000000000') 
	const LOW_GOD_BALANCE = stakeAmount // 2 Gwei  
	const { balance, fetchStatus } = useGetBnbBalance()  
	const { balance: cakeBalance3 } = useTokenBalance(getliraAddress())  
	const hasLowGod2Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
	    const [showHarvestButton, setShowHarvestButton] = useState<boolean>(false); // State for showing harvest button

	const [availableReward, setAvailableReward] = useState<ethers.BigNumber | null>(null);
    const [lastUserActionTime, setLastUserActionTime] = useState<ethers.BigNumber | null>(null);
    const [lastTenUsers, setLastTenUsers] = useState<string[]>([]);
    const [lastTenUserClaims, setLastTenUserClaims] = useState<{ [address: string]: { amount: ethers.BigNumber; timestamp: ethers.BigNumber } }>({});
    const [rewardClaimed, setRewardClaimed] = useState<boolean>(false);
	const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userClaims, setUserClaim] = useState<{ [address: string]: { amount: ethers.BigNumber; timestamp: ethers.BigNumber } }[]>([]); // Add this line
	const toggleTable = () => {
		setShowTable(!showTable);
	};
	
    const calculateRewardInUSD = useCallback(() => {
        if (!availableReward || !hvprice) return 0;
        return parseFloat(ethers.utils.formatUnits(availableReward, 9)) * hvprice;
    }, [availableReward, hvprice]);
    useEffect(() => {
        // Check if availableReward is greater than 1 to show harvest button
        if (availableReward && availableReward.gt(ethers.utils.parseUnits('1', 18))) {
            setShowHarvestButton(false);
        } else {
            setShowHarvestButton(true);
        }
    }, [availableReward]);

    useEffect(() => {
        const fetchContractData = async () => { 
            setLoading(true);
            const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
            const contract = new ethers.Contract(contractAddress, abi, provider);

            try {
                if (account) { 
                    const time = await contract.lastUserActionTime(account);
                    setLastUserActionTime(time);

                    const claim = await contract.getUserClaim(account);
                    setUserClaim(claim);
                }

                const reward = await contract.getAvailableReward();
                setAvailableReward(reward);

                const lastTen = await contract.getLastTenUsers();
                setLastTenUsers(lastTen);

				const userClaims = await Promise.all(lastTen.map(async (user) => {
					const claim = await contract.getUserClaim(user);
					return { user, amount: claim.amount, timestamp: claim.timestamp };
				}));
			
				const lastTenUserClaims = {};
				lastTen.forEach((user, index) => {
					lastTenUserClaims[user] = userClaims[index];
				});
				setLastTenUserClaims(lastTenUserClaims);
			} catch (err) {
				console.error('Error fetching contract data:', err);
			} finally {
				setLoading(false);
			}
		}; 
	
        fetchContractData();

        const interval = setInterval(fetchContractData, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [account, lastTenUsers]);

    const handleCollectTokens = async () => {
        try {
            if (!account) return;

            setLoading(true);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, abi, signer);
            const tx = await contract.claim();
            await tx.wait();
            setRewardClaimed(true);
            console.log('Tokens collected successfully');
        } catch (err) {
            console.error('Error collecting tokens:', err);
        } finally {
            setLoading(false);
        }
    };
	const handleHarvestTokens = async () => {
		try {
		  if (!account) return;
	
		  setLoading(true);
		  const provider = new ethers.providers.Web3Provider(window.ethereum);
		  const signer = provider.getSigner();
		  const contract = new ethers.Contract(newContractAddress, newContractAbi, signer);
		  const tx = await contract.harvest();
		  await tx.wait();
		  console.log('Tokens harvested successfully');
		} catch (err) {
		  console.error('Error harvesting tokens:', err);
		} finally {
		  setLoading(false);
		}
	  };
 
    const formatReward = (reward: ethers.BigNumber) => {
        return ethers.utils.formatUnits(reward, 9).split('.')[0];
    };

    const formatReward2 = (lastUserActionTime: ethers.BigNumber | null, lockTime: number) => {
        if (!lastUserActionTime) return 'Loading...';

        const now = Math.floor(Date.now() / 1000);
        const lastAction = parseInt(lastUserActionTime.toString());
        const expiration = lastAction + lockTime * 3600;

        const remainingTimeSeconds = expiration - now;

        if (remainingTimeSeconds <= 0) {
            return 'NOW';
        }
        const hours = Math.floor(remainingTimeSeconds / 3600);
        const minutes = Math.floor((remainingTimeSeconds % 3600) / 60);
        const seconds = remainingTimeSeconds % 60;

        return `${hours}:${minutes}:${seconds}`;
    };

    const getUserClaim = (user: string) => {
        if (lastTenUserClaims[user]) {
            const { amount, timestamp } = lastTenUserClaims[user];
            return `${formatReward(amount)} at ${new Date(timestamp.toNumber() * 1000).toLocaleString()}`;
        }
        return 'N/A';
    }; 

    return (
		<Page>
		<Flex justifyContent="center" alignItems="center">
			<div style={{ textAlign: 'center' }}>
				<FarmsPoolsRowLoader />
				<AnimatedHeading color="#ffffff">Claimable amount</AnimatedHeading>
    <AnimatedHeading color="#f6c944">{availableReward ? formatReward(availableReward) : 'N/A'} HVI</AnimatedHeading>
    <AnimatedHeading color="#ffffff">{calculateRewardInUSD().toFixed(2)} $</AnimatedHeading>
				<ImageWrapper> 
					<img src="/images/pixel/225.png" alt="ifo bunny" width="200px" height="266px" />
				</ImageWrapper>
				{rewardClaimed ? (
					<p style={{ color: 'green', textAlign: 'center' }}>Reward Claimed!</p>
				) : (
					<Flex justifyContent="center" alignItems="center" mb="20px" mt="-21px">
         {hasLowGod2Balance  && 

<StyledButton width="200px" disabled={!account} onClick={handleCollectTokens} style={{ margin: 'auto' }}>Collect</StyledButton>
} 
{!hasLowGod2Balance  && 
<StyledButton width="200px" disabled={!account} onClick={handleHarvestTokens} style={{ margin: 'auto' }}>Get Anti-bot token</StyledButton>
}
</Flex>
				)}
				{account && (
					<Flex justifyContent="center" alignItems="center" mb="20px" mt="10px">
						<h2 style={{ fontSize: '1em', color: '#f6c944', textAlign: 'center' }}>
							Claim again: {formatReward2(lastUserActionTime, 24)}
						</h2>
					</Flex>
				)}
			</div>
		</Flex>
		<div style={{ backgroundColor: 'black', padding: '20px', borderRadius: '10px', marginTop: '20px', width: '90%', maxWidth: '600px', margin: 'auto' }}>
			{/* Nyíl gomb a táblázat megnyitásához */}
			<div style={{ textAlign: 'center', marginBottom: '10px' }}>
				<Button onClick={toggleTable} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
				<h2 style={{ fontSize: '1.5em', color: '#ffffff', textAlign: 'center' }}>
					{showTable ? '▼' : '►'} Last Ten Users Claims 
					</h2>
				</Button>
			</div>
			{/* Táblázat megjelenítése, ha a showTable állapot igaz */}
			{showTable && (
				<table style={{ margin: 'auto', borderCollapse: 'collapse', color: '#ffffff', width: '100%' }}>
				<colgroup>
					<col style={{ width: '20%' }} />
					<col style={{ width: '30%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '35%' }} />
				</colgroup>
				<thead>
					<tr style={{ borderBottom: '1px solid #ffffff' }}>
						<th style={{ padding: '2px', textAlign: 'center', color: '#f6c944' }}>User</th>
						<th style={{ padding: '2px', textAlign: 'center', color: '#f6c944' }}>Amount(HVI)</th>
						<th style={{ padding: '2px', textAlign: 'center', color: '#f6c944' }}>(BUSD)</th>
						<th style={{ padding: '4px', textAlign: 'center', color: '#f6c944' }}>Time</th>
					</tr>
				</thead>
				<tbody>
					{lastTenUsers.slice().reverse().map((user) => {
						const shortenedAddress = `${user.substring(0, 2)}...${user.substring(user.length - 4)}`;
						const bscLink = `https://bscscan.com/address/${user}`;
						return (
							<tr key={user} style={{ borderBottom: '1px solid #ffffff' }}>
								<td style={{ padding: '2px', textAlign: 'center', color: '#62d5a3', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
									<a href={bscLink} target="_blank" rel="noopener noreferrer" style={{ color: '#62d5a3', textDecoration: 'none' }}>
										{shortenedAddress}
									</a>
								</td>
								<td style={{ padding: '2px', textAlign: 'center', color: '#62d5a3', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
									{getUserClaim(user).split(' at ')[0]}
								</td>
								<td style={{ padding: '2px', textAlign: 'center', color: '#62d5a3', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
									{lastTenUserClaims[user] && (parseFloat(ethers.utils.formatUnits(lastTenUserClaims[user].amount, 9)) * hvprice).toFixed(2)}
								</td>
								<td style={{ padding: '4px', textAlign: 'center', color: '#62d5a3', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
									{lastTenUserClaims[user] && new Date(lastTenUserClaims[user].timestamp.toNumber() * 1000).toLocaleString()}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			
			)}
		</div>
	</Page>
    );
}

export default VizslaSwapFaucet;