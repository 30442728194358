import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core/core/core';
import { ethers } from 'ethers';
import { Button, ButtonMenu, ButtonMenuItem, Flex } from '@pancakeswap/uikit';
import { createClient } from '@supabase/supabase-js';
import { useTranslation } from 'contexts/Localization';
import {
  Avatar,
  UserButton,
  UserList,
  UserListItem,
  Sidebar,
  MessageContainer,
  Container,
  Timestamp,
  ChatBox,
  SendButton,
  Input,
  Message,
  Title,
} from './Styled';
import contractABI from './contractABI.json';
import NumberPurchaseABI from './NumberPurchaseABI.json';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('supabaseUrl and supabaseKey are required.');
}

const supabase = createClient(supabaseUrl, supabaseKey);

const Vote = () => {
  const { account } = useWeb3React();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [receiver, setReceiver] = useState('');
  const { t } = useTranslation();
  const [userNumbers, setUserNumbers] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [cooldown, setCooldown] = useState(0);
  const [userList, setUserList] = useState([]);
  const chatBoxRef = useRef(null);
  const [hideSelf, setHideSelf] = useState(false);
  const location = useLocation();
  const [names, setNames] = useState({});
  const [nameToAddressMap, setNameToAddressMap] = useState({});
  const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';
  const contractAddress2 = '0xBE8C7E554C89e064ED7229952036c3678cd10180';
  const cachedUserData = useRef({});
  const provider = useMemo(() => new ethers.providers.Web3Provider(window.ethereum), []);
  const signer = useMemo(() => provider.getSigner(), [provider]);
  const contract = useMemo(() => new ethers.Contract(contractAddress, contractABI, signer), [signer]);
  const contract2 = useMemo(() => new ethers.Contract(contractAddress2, NumberPurchaseABI, signer), [signer]);
  const adminAddress = '0xEa9C8f2D9d91cF44206F0d869b9cc39897a13Ae1';

  useEffect(() => {
    let messageChannel;
  
    const fetchMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('private_messages')
          .select('*')
          .or(`sender.eq.${account},receiver.eq.${account}`)
          .order('timestamp', { ascending: true });
  
        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }
  
        setMessages(data);
  
        const usersWithMessages = new Set();
  
        data.forEach((message) => {
          usersWithMessages.add(message.sender);
          usersWithMessages.add(message.receiver);
        });
  
        const sortedUsers = Array.from(usersWithMessages).sort((a, b) => {
          const lastMessageA = data.filter(msg => msg.sender === a || msg.receiver === a).pop()?.timestamp || 0;
          const lastMessageB = data.filter(msg => msg.sender === b || msg.receiver === b).pop()?.timestamp || 0;
          return new Date(lastMessageB) - new Date(lastMessageA);
        });
  
        setUserList([adminAddress, ...sortedUsers]);
  
        messageChannel = supabase
          .channel('private_messages')
          .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'private_messages' }, payload => {
            const newMessage = payload.new;
            if (newMessage.sender === account || newMessage.receiver === account) {
              setMessages(prevMessages => {
                const updatedMessages = [...prevMessages, newMessage];
  
                const updatedUserList = new Set([...usersWithMessages, newMessage.sender, newMessage.receiver]);
  
                setUserList([adminAddress, ...Array.from(updatedUserList).sort((a, b) => {
                  const lastMessageA = updatedMessages.filter(msg => msg.sender === a || msg.receiver === a).pop()?.timestamp || 0;
                  const lastMessageB = updatedMessages.filter(msg => msg.sender === b || msg.receiver === b).pop()?.timestamp || 0;
                  return new Date(lastMessageB) - new Date(lastMessageA);
                })]);
  
                return updatedMessages;
              });
            }
          })
          .subscribe();
  
      } catch (error) {
        console.error('Unexpected error fetching messages:', error);
      }
    };
  
    fetchMessages();
  
    return () => {
      if (messageChannel) {
        supabase.removeChannel(messageChannel);
      }
    };
  }, [account]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, selectedUser]);

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [cooldown]);

  const handleSend = async () => {
    if (cooldown > 0) return;
  
    const currentReceiver = selectedUser || receiver;
    const resolvedReceiver = nameToAddressMap[currentReceiver] || currentReceiver;
  
    // Prevent sending a message to oneself
    if (resolvedReceiver.trim() === '' || newMessage.trim() === '' || resolvedReceiver === account) return;
  
    const message = {
      text: newMessage,
      sender: account,
      receiver: resolvedReceiver,
      timestamp: new Date().toISOString(),  // UTC időbélyegző
    };
  
    try {
      const { error } = await supabase
        .from('private_messages')
        .insert([message]);
  
      if (error) {
        console.error('Error sending message:', error.message, error.details, error.hint);
        return;
      }
  
      setNewMessage('');
      setCooldown(5);
  
      // Update the user list after sending the message
      setUserList(prevUserList => {
        const newUserList = [...prevUserList];
        
        // Filter out the user's own account from the list
        const filteredUserList = newUserList.filter(user => user !== account);
        
        // Remove duplicate entries for the admin
        const uniqueUserList = Array.from(new Set(filteredUserList));
        
        // Move the admin to the first position if present
        if (uniqueUserList.includes(adminAddress)) {
          uniqueUserList.splice(uniqueUserList.indexOf(adminAddress), 1);
          uniqueUserList.unshift(adminAddress);
        }
        
        return uniqueUserList;
      });
  
      if (!selectedUser) {
        setReceiver('');
      }
    } catch (error) {
      console.error('Unexpected error sending message:', error);
    }
  };

  useEffect(() => {
    if (!account || userList.length === 0) return;
  
    const fetchNamesAndNumbers = async () => {
      const newNames = {};
      const newNumbers = {};
      const newMap = {};
  
      await Promise.all(userList.map(async (user) => {
        if (cachedUserData.current[user]) {
          const { name, number } = cachedUserData.current[user];
          newNames[user] = name;
          newNumbers[user] = number;
          newMap[name] = user;
          return;
        }
  
        try {
          const { data: userData, error } = await supabase
            .from('user_data')
            .select('name, number')
            .eq('account', user)
            .single();
  
          let name = userData ? userData.name : null;
          let userNumber = userData ? userData.number : null;
  
          if (!name || !userNumber) {
            name = await contract.getName(user);
            const userPurchase = await contract2.userPurchases(user);
            userNumber = userPurchase.number.toNumber();
  
            if (user !== adminAddress) {
              await supabase.from('user_data').insert([{ account: user, name, number: userNumber }]);
            }
          }
  
          if (name) {
            newNames[user] = name;
            newMap[name] = user;
          }
          newNumbers[user] = userNumber;
  
          cachedUserData.current[user] = { name, number: userNumber };
        } catch (error) {
          console.error(`Error fetching data for ${user}:`, error);
        }
      }));
  
      setNames(prev => ({ ...prev, ...newNames }));
      setNameToAddressMap(prev => ({ ...prev, ...newMap }));
      setUserNumbers(prev => ({ ...prev, ...newNumbers }));
    };
  
    fetchNamesAndNumbers();
  }, [userList, account, adminAddress, contract, contract2]);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleReceiverClick = () => {
    setSelectedUser(null);
    setReceiver('');
  };

  const getActiveIndex = (pathname: string): number => {
    if (
      pathname.includes('/lockedstaking/all') ||
      pathname.includes('/lockedstaking/history')
    ) {
      return 0;
    }
    return 1;
  };

  const getSenderName = (sender) => {
    if (sender === adminAddress) {
      return 'Admin';
    }
    const name = names[sender];
    return name ? `${name}` : `${sender.substring(0, 5)}...${sender.substring(sender.length - 5)}`;
  };

  const getSenderImage = (sender) => {
    const userNumber = userNumbers[sender];
    console.log(`Sender: ${sender}, UserNumber: ${userNumber}`);
  
    if (userNumber) {
      const formattedNumber = userNumber < 10 ? `0${userNumber}` : `${userNumber}`;
      return `images/pixel/${formattedNumber}.jpg`;
    }
  
    return 'images/pixel/200.png';  // Alapértelmezett kép, ha nincs más
  };

  const getMessagesForUser = user => {
    return messages.filter(msg =>
      (msg.sender === user && msg.receiver === account) ||
      (msg.sender === account && msg.receiver === user)
    );
  };

  const formatTimestamp = timestamp => {
    const date = new Date(timestamp); // Eredeti UTC idő
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
  };

  return (
    <>
      <Title>VizslaChat</Title>
      <Flex alignItems="center" justifyContent="center" mb="20px">
        <ButtonMenu activeIndex={getActiveIndex(location.pathname)} scale="sm" variant="subtle">
          <ButtonMenuItem id="swap-nav-link" to="/chat" as={Link}>
            {t('Public')}
          </ButtonMenuItem>
          <ButtonMenuItem id="swap-nav-link" to="/privatechat" as={Link} mt="4px">
            {t('Private')}
          </ButtonMenuItem>
          <ButtonMenuItem id="swap-nav-link" to="/nameregistration" as={Link} mt="4px">
            {t('Settings')}
          </ButtonMenuItem>
        </ButtonMenu>
      </Flex>
      <Container>
        <Sidebar>
          <h3>Users</h3>
          <Input
            type="text"
            placeholder="Address or Name."
            value={receiver}
            onChange={e => setReceiver(e.target.value)}
            onClick={handleReceiverClick}
            onKeyPress={handleKeyPress}
            disabled={cooldown > 0}
          />
          <UserList>
            {userList
              .filter(user => user === adminAddress || getMessagesForUser(user).length > 0)  // Admin always shows, others only if messages exist
              .map(user => (
                <UserListItem key={user} isActive={selectedUser === user} isTop10={false}>
                  <UserButton
                    onClick={() => setSelectedUser(user)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectedUser(user);
                      }
                    }}
                    style={{ color: user === adminAddress ? 'gold' : 'white' }}  // Set color conditionally
                  >
                    <Avatar src={getSenderImage(user)} alt="user" />
                    <span>{getSenderName(user)}</span>
                  </UserButton>
                </UserListItem>
              ))}
          </UserList>
        </Sidebar>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ChatBox ref={chatBoxRef}>
            {selectedUser ? (
              getMessagesForUser(selectedUser).length > 0 ? (
                getMessagesForUser(selectedUser).map(msg => (
                  <MessageContainer key={msg.timestamp}>
                    <Avatar src={getSenderImage(msg.sender)} alt="sender" />
                    <Message>
                      <b>{getSenderName(msg.sender)}:</b> {msg.text}
                      <Timestamp>{formatTimestamp(msg.timestamp)}</Timestamp>  {/* Helyi időzóna szerinti megjelenítés */}
                    </Message>
                  </MessageContainer>
                ))
              ) : (
                <Message>No messages yet.</Message>
              )
            ) : (
              <Message>Send a message to a user by either their address or name.</Message>
            )}
          </ChatBox>
          <Input
            type="text"
            placeholder={`Write a message${cooldown > 0 ? ` (${cooldown}s)` : ''}`}
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={cooldown > 0}
          />
          <SendButton onClick={handleSend} disabled={cooldown > 0}>Send</SendButton>
        </div>
      </Container>
    </>
  );
};

export default Vote;
