import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core/core/core'
import { Heading, Flex, Skeleton, Text, Button, Box, HelpIcon, useModal , ButtonMenu,ButtonMenuItem, Card} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault  } from 'state/pools/hooks'
import {useVote1, useVote2, useVote3, useVote4} from 'hooks/useTokenBalance'
import { usePollFarmsData } from 'state/farms/hooks'
import { getCakeAddress} from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import Balance from 'components/Balance'
import PoolCard from './components/PoolCard'
import CakeVaultCard from './components/CakeVaultCard'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'
import ModalInfo from './components/ModalInfo'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`
const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  height: 1px;
  margin-top: 32px ;
  margin-bottom: 32px ;
  width: 100%;
`
const StyledModalContainer = styled(Box)`
  max-width: 1200px;
  width: 100%;
  background-color: rgba(0,0,0);
  border-radius: 25px;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 1200px;
    width: 100%;
    background-color: rgba(0,0,0);
    border-radius: 25px;
  }
`
const StyledModalContainer2 = styled(Box)`
  max-width: 250px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: -5px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 250px;
    border-radius: 5px;
    margin-top: -35px;
  }
`

const PoolControls = styled(Flex)`
display: flex;
width: 100%;
align-items: center;
position: relative;

justify-content: space-between;
flex-direction: column;
margin-bottom: 35px;
border-radius: 16px;

${({ theme }) => theme.mediaQueries.sm} {
  flex-direction: row;
    flex-wrap: wrap;
    border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
    padding: 20px 32px;
    margin-bottom: 50;
}
`
const Lol = styled(Box)`
margin-right: 25px;
margin-left: 25px;
align-items: left;
margin-top: 10px;
justify-content: left;
${({ theme }) => theme.mediaQueries.md} {
  margin-right: 55px;
  margin-left: 55px;
  align-items: left;
  justify-content: left;
  margin-top: 10px;
}
`
const Lol2 = styled(Flex)`
background-color: rgb(19,47,46);


${({ theme }) => theme.mediaQueries.md} {
  background-color:  rgb(19,47,46);

}
`
const ImageWrapper = styled.div`

${({ theme }) => theme.mediaQueries.md} {


}
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`


const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`

const ScrollBox = styled(Box)`
background-color: rgba(0,0,0);
padding: 10px;
width: 210px;
margin-top: 20px;
margin-bottom: -10px;
border: 0.05px solid ${({ theme }) => theme.colors.secondary};
border-radius: 10px;
${({ theme }) => theme.mediaQueries.md} {
  background-color: rgba(0,0,0);
  width: 210px;
  margin-bottom: -20px;
  padding: 10px;
  border: 0.05px solid ${({ theme }) => theme.colors.text};
  border-radius: 10px;
}
`
const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const NUMBER_OF_POOLS_VISIBLE = 12

const VoteClosed: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'pancake_pool_staked' })
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const cakeAutoVault = { ...cakePool, isAutoVault: false }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

 
  const all = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL100000')
  const getActiveIndex = (pathname: string): number => {
    if (
      pathname.includes('/voting/open') ||
      pathname.includes('/voting/closed')
    ) {
      return 1
    }
    return 0
  }

 const F = getBalanceNumber(useVote1(getCakeAddress()))
 const F1 = getBalanceNumber(useVote2(getCakeAddress()))
 const F2 = getBalanceNumber(useVote3(getCakeAddress()))
 const F3 = getBalanceNumber(useVote4(getCakeAddress()))
const ALL = F + F2 + F1 + F3 + 2040

  const TooltipComponent = ({ fee }: { fee: number }) => (
    <>
      <Text mb="16px">{t('This bounty is given as a reward for providing a service to other users.')}</Text>
      <Text mb="16px">
        {t(
          'Whenever you successfully claim the bounty, you’re also helping out by activating the Auto VIZSLASWAP Pool’s compounding function for everyone.',
        )}
      </Text>
      <Text style={{ fontWeight: 'bold' }}>
        {t('Auto-Compound Bounty: %fee%% of all Auto VIZSLASWAP pool users pending yield', { fee: fee / 100 })}
      </Text>
    </>
  )
  const [onPresentBountyModal] = useModal(<ModalInfo TooltipComponent={TooltipComponent} />)

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const showFinishedPools = location.pathname.includes('history')

  const showall = location.pathname.includes('closed')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools
  if (showFinishedPools) {
    chosenPools = finishedPools
  } else {
    chosenPools =  openPools
  }
  if (showall) {
    chosenPools =  all
  } 



  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
        pool.isAutoVault ? (
          <CakeVaultCard key="auto-cake" pool={pool} showStakedOnly={stakedOnly} />
        ) : (
          <PoolCard key={pool.sousId} pool={pool} account={account} />
        ),
      )}
    </CardLayout>
  )

  const tableLayout = <PoolsTable pools={chosenPools} account={account} userDataLoaded={userDataLoaded} />

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="secondary" mb="24px">
              {t('Voting')}
            </Heading>
            <Heading scale="md" color="text">
              {t('Have your say in the future of the')}
            </Heading>
            <Heading scale="md" color="text">
              {t('VizslaSwap Ecosystem.')}
            </Heading>
          </Flex>
        </Flex>
     
    </PageHeader>

      <Page>
      <Flex justifyContent="center" alignItems="center" mb="20px">

      <ButtonMenu activeIndex={getActiveIndex(location.pathname)}  scale="sm" variant="subtle">
        <ButtonMenuItem id="swap-nav-link"  to="/voting/open"  as={Link}>
          {t('Vote Now')}
        </ButtonMenuItem>
          <ButtonMenuItem id="swap-nav-link" to="/voting/closed"  as={Link} mt="4px">
            {t('Closed')}
          </ButtonMenuItem>
      </ButtonMenu>
      </Flex>
      <StyledModalContainer>

<Flex flex="1" flexDirection="column" justifyContent="left" alignItems="left" mb='20px' mr={['8px', 0]}>
      <Lol>
      <Heading  scale="md" color="secondary" mt="20px" mb="10px">
        {t('7% increase for 2 week')}
      </Heading>
      <Flex justifyContent="left" alignItems="left">
          <ImageWrapper>
  <img src="/images/pixel/time.png" alt="ifo bunny" width="20px" height="20px"/>
</ImageWrapper>
      <Text  mb="10px" color="text" fontSize='14px' ml="10px" >
        {t('End Date 2023-1-27')}
      </Text>
 
      </Flex>
      <Text  color="text" fontSize='15px'  mb="10px" >
        {t('Witch of those Minings will be given a 7% boost?')}
      </Text>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
      <Button
    width="100px"
    variant='secondary2'
  >
     <ButtonText color="text" bold fontSize="15px">
     {t('0%')}
  </ButtonText>
  </Button>
  <Text  color="secondary" fontSize='15px' ml="10px" bold  >
        {t('VIZSLASWAP-BNB (PANCAKE)')}
      </Text>
      </Flex>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('4%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('VIZSLASWAP-BNB (APE)')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('HVI-BUSD')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="30px">
    
    <Button
  width="100px"
  variant='secondary'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('96%')}
</ButtonText>
</Button>
<Text  color="primary" fontSize='15px' ml="10px" bold  >
      {t('HVI-BNB')}
    </Text>
    </Flex>
      </Lol>

</Flex>


  
  <div ref={loadMoreRef} />


  </StyledModalContainer>
      <StyledModalContainer>

<Flex flex="1" flexDirection="column" justifyContent="left" alignItems="left" mb='20px' mr={['8px', 0]}>
      <Lol>
      <Heading  scale="md" color="secondary" mt="20px" mb="10px">
        {t('15% increase for 2 week')}
      </Heading>
      <Flex justifyContent="left" alignItems="left">
          <ImageWrapper>
  <img src="/images/pixel/time.png" alt="ifo bunny" width="20px" height="20px"/>
</ImageWrapper>
      <Text  mb="10px" color="text" fontSize='14px' ml="10px" >
        {t('End Date 2023-1-25')}
      </Text>
 
      </Flex>
      <Text  color="text" fontSize='15px'  mb="10px" >
        {t('Witch of those Minings will be given a 15% boost?')}
      </Text>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
      <Button
    width="100px"
    variant='secondary'
  >
     <ButtonText color="text" bold fontSize="15px">
     {t('63%')}
  </ButtonText>
  </Button>
  <Text  color="primary" fontSize='15px' ml="10px" bold  >
        {t('BVC-BNB')}
      </Text>
      </Flex>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('TRIP-VIZSLASWAP')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('LGC-BNB')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="30px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('37%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('OPEN NEW MINING')}
    </Text>
    </Flex>
      </Lol>

</Flex>


  
  <div ref={loadMoreRef} />


  </StyledModalContainer>

      <StyledModalContainer>

<Flex flex="1" flexDirection="column" justifyContent="left" alignItems="left" mb='20px' mr={['8px', 0]}>
      <Lol>
      <Heading  scale="md" color="secondary" mt="20px" mb="10px">
        {t('10% increase for 1 week')}
      </Heading>
      <Flex justifyContent="left" alignItems="left">
          <ImageWrapper>
  <img src="/images/pixel/time.png" alt="ifo bunny" width="20px" height="20px"/>
</ImageWrapper>
      <Text  mb="10px" color="isten" fontSize='14px' ml="10px" >
        {t('End Date 2023-1-17')}
      </Text>
 
      </Flex>
      <Text  color="text" fontSize='15px'  mb="10px" >
        {t('Which of those Minings will be given a 10% boost?')}
      </Text>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
      <Button
    width="100px"
    variant='secondary2'
  >
     <ButtonText color="text" bold fontSize="15px">
     {t('0%')}
  </ButtonText>
  </Button>
  <Text  color="secondary" fontSize='15px' ml="10px" bold  >
        {t('BABY-BNB')}
      </Text>
      </Flex>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('83%')}
</ButtonText>
</Button>
<Text  color="primary" fontSize='15px' ml="10px" bold  >
      {t('HVI-BUSD')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('LIRA-VIZSLASWAP')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="30px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('17%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('HVI-VIZSLASWAP')}
    </Text>
    </Flex>
      </Lol>

</Flex>


  
  <div ref={loadMoreRef} />


  </StyledModalContainer>

      <StyledModalContainer>

<Flex flex="1" flexDirection="column" justifyContent="left" alignItems="left" mb='20px' mr={['8px', 0]}>
      <Lol>
      <Heading  scale="md" color="secondary" mt="20px" mb="10px">
        {t('10% increase for 1 week')}
      </Heading>
      <Flex justifyContent="left" alignItems="left">
          <ImageWrapper>
  <img src="/images/pixel/time.png" alt="ifo bunny" width="20px" height="20px"/>
</ImageWrapper>
      <Text  mb="10px" color="isten" fontSize='14px' ml="10px" >
        {t('End Date 2023-1-9')}
      </Text>
 
      </Flex>
      <Text  color="text" fontSize='15px'  mb="10px" >
        {t('Which of those Minings will be given a 10% boost?')}
      </Text>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
      <Button
    width="100px"
    variant='secondary2'
  >
     <ButtonText color="text" bold fontSize="15px">
     {t('17%')}
  </ButtonText>
  </Button>
  <Text  color="secondary" fontSize='15px' ml="10px" bold  >
        {t('LAMEA-BNB')}
      </Text>
      </Flex>
      <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('LGC-BNB')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="10px">
    
    <Button
  width="100px"
  variant='secondary'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('83%')}
</ButtonText>
</Button>
<Text  color="primary" fontSize='15px' ml="10px" bold  >
      {t('BVC-BNB')}
    </Text>
    </Flex>
    <Flex justifyContent="left" alignItems="center"  mb="30px">
    
    <Button
  width="100px"
  variant='secondary2'
>
   <ButtonText color="text" bold fontSize="15px">
   {t('0%')}
</ButtonText>
</Button>
<Text  color="secondary" fontSize='15px' ml="10px" bold  >
      {t('BABYVIZSLA-BNB')}
    </Text>
    </Flex>
      </Lol>

</Flex>


  
  <div ref={loadMoreRef} />


  </StyledModalContainer>
      <StyledModalContainer>

      <Flex flex="1" flexDirection="column" justifyContent="left" alignItems="left" mr={['8px', 0]}>
            <Lol>
            <Heading  scale="md" color="secondary" mt="20px" mb="10px">
              {t('30% increase for 1 month')}
            </Heading>
            <Flex justifyContent="left" alignItems="left">
                <ImageWrapper>
        <img src="/images/pixel/time.png" alt="ifo bunny" width="20px" height="20px"/>
      </ImageWrapper>
            <Text  mb="10px" color="isten" fontSize='14px' ml="10px" >
              {t('End Date 2022-12-25')}
            </Text>
       
            </Flex>
            <Text  color="text" fontSize='15px'  mb="10px" >
              {t('Which of those Minings will be given a 30% boost?')}
            </Text>
            <Flex justifyContent="left" alignItems="center"  mb="10px">
          
            <Button
          width="100px"
          variant='secondary'
        >
           <ButtonText color="text" bold fontSize="15px">
           {t('51%')}
        </ButtonText>
        </Button>
        <Text  color="primary" fontSize='15px' ml="10px" bold  >
              {t('LGC-VIZSLASWAP')}
            </Text>
            </Flex>
            <Flex justifyContent="left" alignItems="center"  mb="10px">
          
          <Button
        width="100px"
        variant='secondary2'
      >
         <ButtonText color="text" bold fontSize="15px">
         {t('8%')}
      </ButtonText>
      </Button>
      <Text  color="secondary" fontSize='15px' ml="10px" bold  >
            {t('BVC-VIZSLASWAP')}
          </Text>
          </Flex>
          <Flex justifyContent="left" alignItems="center"  mb="10px">
          
          <Button
        width="100px"
        variant='secondary2'
      >
         <ButtonText color="text" bold fontSize="15px">
         {t('0%')}
      </ButtonText>
      </Button>
      <Text  color="secondary" fontSize='15px' ml="10px" bold  >
            {t('LAMEA-VIZSLASWAP')}
          </Text>
          </Flex>
          <Flex justifyContent="left" alignItems="center"  mb="30px">
          
          <Button
        width="100px"
        variant='secondary2'
      >
         <ButtonText color="text" bold fontSize="15px">
         {t('41%')}
      </ButtonText>
      </Button>
      <Text  color="secondary" fontSize='15px' ml="10px" bold  >
            {t('BABYVIZSLA-VIZSLASWAP')}
          </Text>
          </Flex>
            </Lol>

</Flex>


        
        <div ref={loadMoreRef} />
  

        </StyledModalContainer>
 
      </Page>
    </>
  )
}

export default VoteClosed
