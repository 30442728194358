import React from 'react'
import { Button, AutoRenewIcon, Skeleton , Text, Flex} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import styled, { keyframes } from 'styled-components'
import { useERC20 } from 'hooks/useContract'
import { getAddress } from 'utils/addressHelpers'
import { Pool } from 'state/types'
import { useApprovePool } from '../../../hooks/useApprove'

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`

interface ApprovalActionProps {
  pool: Pool
  isLoading?: boolean
}

const ApprovalAction: React.FC<ApprovalActionProps> = ({ pool, isLoading = false }) => {
  const { sousId, stakingToken, earningToken , withdrawtime4} = pool
  const { t } = useTranslation()
  const stakingTokenContract = useERC20(stakingToken.address ? getAddress(stakingToken.address) : '')
  const { handleApprove, requestedApproval } = useApprovePool(stakingTokenContract, sousId, earningToken.symbol)

  return (
    <>
      {isLoading ? (
        <Skeleton width="100%" height="52px" />
      ) : (
          <Button
          isLoading={requestedApproval}
          endIcon={requestedApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
          disabled={requestedApproval}
          onClick={handleApprove}
          width="100%"
          variant='secondary2'
        >
           <ButtonText color="text" bold fontSize="13px">
          {withdrawtime4} 
        </ButtonText>
        </Button>
      ) 
      }
        {isLoading ? (
        <Skeleton width="100%" height="52px" />
      ) : (
        <Flex alignItems="center" justifyContent="center" mt="5px" >
          <Text
          onClick={handleApprove}
          color="text" 
          fontSize="11px"
          bold
        >
         {t('Approve contract before Voting')}
        </Text>
        </Flex>
      ) 
      }
    </>
    
  )
}


export default ApprovalAction
