import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core/core/core';
import styled from 'styled-components';
import { Button, Input } from '@pancakeswap/uikit';
import NumberPurchaseABI from './NumberPurchaseABI.json';
import TokenABI from './TokenABI.json';
import {
  Container,
  HeaderContainer,
  Title,
  ArrowIcon,
  Content,
  ImageGrid,
  ImageItem,
  StyledButton,
  InfoText
} from './StyledComponents';
import './Kep.css';

const contractAddress = "0xBE8C7E554C89e064ED7229952036c3678cd10180"; 
const tokenAddress = "0xadaae082237cb1772c9e079db95c117e6dd0c5af"; 

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px; /* Toplista lejjebb helyezése */
`;

const TableHeader = styled.th`
  border-bottom: 2px solid #62d5a3;
  padding: 10px;
  text-align: left;
  color: #62d5a3;
`;

const TableData = styled.td`
  padding: 10px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid #62d5a3;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1e1e1e;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled(Button)`
  margin: 0 5px;
`;

const Kep = () => {
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [numberStatistics, setNumberStatistics] = useState<number[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [userNumber, setUserNumber] = useState<number | null>(null); // Felhasználó száma
  const [currentPage, setCurrentPage] = useState(1); // Oldal index
  
  const { account } = useWeb3React();

  const provider = useMemo(() => new ethers.providers.Web3Provider(window.ethereum), []);
  const contract = useMemo(() => new ethers.Contract(contractAddress, NumberPurchaseABI, provider), [provider]);

  const itemsPerPage = 10; // Minden oldalon megjelenítendő elemek száma
  const maxPages = 5; // Maximális oldalak száma

  const checkApprovalStatus = useCallback(async () => {
    if (!account) return;

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);

    const allowance = await tokenContract.allowance(account, contractAddress);
    const amountToApprove = ethers.utils.parseUnits("2000", 18);

    if (allowance.gte(amountToApprove)) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }

    // Ellenőrizzük, hogy van-e a felhasználónak már megvásárolt száma
    try {
      const userPurchase = await contract.userPurchases(account);
      if (userPurchase.number && userPurchase.number.toNumber() !== 0) {
        setUserNumber(userPurchase.number.toNumber());
      } else {
        setUserNumber(null);
      }
    } catch (error) {
      console.error('Failed to fetch user purchase:', error);
    }
  }, [account, provider, contract]);

  const loadStatistics = useCallback(async () => {
    if (!provider) return;

    const contract = new ethers.Contract(contractAddress, NumberPurchaseABI, provider);
    
    const promises = []; 
    for (let i = 1; i <= 9; i++) {
      promises.push(contract.getNumberStatistics(i));
    }
    
    const stats = await Promise.all(promises);
    setNumberStatistics(stats.map(stat => stat.toNumber()));
  }, [provider]);

  const loadPurchaseHistory = useCallback(async () => {
    if (!provider) return;

    const contract = new ethers.Contract(contractAddress, NumberPurchaseABI, provider);
    
    const [users, numbers, timestamps] = await contract.getPurchaseHistory();
    setPurchaseHistory(users.map((user, index) => ({
      user,
      number: numbers[index].toNumber(),
      timestamp: new Date(timestamps[index].toNumber() * 1000).toLocaleString(),
    })));
  }, [provider]);

  useEffect(() => {
    if (account) {
      checkApprovalStatus();
      loadStatistics();
      loadPurchaseHistory();
    }
  }, [account, checkApprovalStatus, loadStatistics, loadPurchaseHistory]);

  const approveTokens = async () => {
    if (!account) return;

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);
    
    const amountToApprove = ethers.utils.parseUnits("2000", 18);
  
    try {
      const tx = await tokenContract.approve(contractAddress, amountToApprove);
      await tx.wait();
      setIsApproved(true); // Manually set to true after approval
      checkApprovalStatus(); // Double-check approval status
    } catch (error) {
      console.error("Approve failed", error);
    }
  };

  const buyNumber = async (number: number) => {
    if (!isApproved || !account) return;
  
    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);
  
    try {
      const tx = await contractWithSigner.buyNumber(number, {
        gasLimit: ethers.utils.hexlify(400000), 
      });
  
      await tx.wait();
  
      loadStatistics();
      loadPurchaseHistory();
      checkApprovalStatus(); // Update user number after buying
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };
  
  const changeNumber = async (newNumber: number) => {
    if (!userNumber || !account) return;

    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);

    try {
      const tx = await contractWithSigner.changeNumber(newNumber);
      await tx.wait();

      loadStatistics();
      loadPurchaseHistory();
      checkApprovalStatus(); // Update user number after changing
    } catch (error) {
      console.error("Change number failed:", error);
    }
  };

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  // Lapozási logika
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Container>
      <HeaderContainer onClick={toggleBox}>
        <Title>Get Profile Image</Title>
        <ArrowIcon>{isBoxOpen ? '▲' : '▼'}</ArrowIcon>
      </HeaderContainer>
      {isBoxOpen && (
        <Content>
          {!account && <InfoText>Please connect your wallet to proceed.</InfoText>}
          <ImageGrid>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
              <ImageItem key={`number-${number}`}>
                <img src={`images/pixel/0${number}.jpg`} alt={`Number ${number}`} />
                {!isApproved ? (
                  <StyledButton onClick={approveTokens}>Approve</StyledButton>
                ) : userNumber === null ? (
                  <StyledButton onClick={() => buyNumber(number)}>BUY</StyledButton>
                ) : (
                  <StyledButton onClick={() => changeNumber(number)}>CHANGE</StyledButton>
                )}
                <p>{numberStatistics[number - 1]} users have bought this number</p>
              </ImageItem>
            ))}
          </ImageGrid>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>User</TableHeader>
                <TableHeader>Number</TableHeader>
                <TableHeader>Timestamp</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((entry, index) => (
                <TableRow key={entry.user || `${entry.user}-${entry.number}-${index}`}>
                  <TableData>{entry.user}</TableData>
                  <TableData>{entry.number}</TableData>
                  <TableData>{entry.timestamp}</TableData>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          
          {/* Pagination */}
          <PaginationContainer>
            <PaginationButton onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            <PaginationButton onClick={nextPage} disabled={currentPage === maxPages || indexOfLastItem >= purchaseHistory.length}>
              Next
            </PaginationButton>
          </PaginationContainer>
        </Content>
      )}
    </Container>
  );
};

export default Kep;
