import React, { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js/bignumber'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useFarmUser } from 'state/farms/hooks'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { getaceAddress, getataliraAddress, getbabyAddress,getFarm1Address,getFarm11Address,getFarm12Address,getFarm13Address, getFarm14Address,getFarm15Address,getbabyvizslaAddress, getFarm3Address, getbtntyAddress, getbuildAddress, getBusdAddress, getbvcAddress, getCakeAddress , getetcAddress, getethAddress, getexzoAddress, getFarm2Address, getfpsAddress, getHVIAddress, getLAMEAAddress, getlgcAddress, getliraAddress, getltcAddress, gettripAddress, getWbnbAddress, getFarm4Address, getFarm5Address, getFarm6Address, getFarm7Address, getFarm8Address, getFarm9Address, getFarm10Address, getFarm16Address, getFarm17Address, getFarm18Address, getFarm19Address, getFarm20Address, getFarm21Address, getFarm22Address, getFarm23Address, getFarm24Address, getFarm25Address, getFarm26Address, getFarm27Address, getVNAddress, getcubyAddress, getbtcAddress, getFRTCAddress, getHmttAddress} from 'utils/addressHelpers'
import WalletInfo from './WalletInfo'
import WalletInfo2 from './WalletInfo2'
import WalletTransactions from './WalletTransactions'

export enum WalletView2 {
  WALLET_INFO,
  WALLET_INFO2,
  TRANSACTIONS,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView2
}

export const LOW_BNB_BALANCE2 = new BigNumber('2000000000') // 2 Gwei
export const LOW_GOD_BALANCE = new BigNumber('2000000000') // 2 Gwei

const ModalHeader = styled(UIKitModalHeader)`
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 16px 24px;
`

const WalletModal2: React.FC<WalletModalProps> = ({ initialView = WalletView2.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance } = useTokenBalance(getCakeAddress())
  const { balance: cakeBalance2 } = useTokenBalance(getHVIAddress())
  const { balance: cakeBalance3 } = useTokenBalance(getBusdAddress())
  const { balance: cakeBalance4 } = useTokenBalance(getLAMEAAddress())
  const { balance: cakeBalance5 } = useTokenBalance(getlgcAddress())
  const { balance: cakeBalance6 } = useTokenBalance(getbvcAddress())
  const { balance: cakeBalance7 } = useTokenBalance(getbabyvizslaAddress())
  const { balance: cakeBalance8 } = useTokenBalance(getWbnbAddress())
  const { balance: cakeBalance9 } = useTokenBalance(getfpsAddress())
  const { balance: cakeBalance10 } = useTokenBalance(getexzoAddress())
  const { balance: cakeBalance11 } = useTokenBalance(getataliraAddress())
  const { balance: cakeBalance12 } = useTokenBalance(gettripAddress())
  const { balance: cakeBalance13 } = useTokenBalance(getbuildAddress())
  const { balance: cakeBalance14 } = useTokenBalance(getliraAddress())
  const { balance: cakeBalance15 } = useTokenBalance(getaceAddress())
  const { balance: cakeBalance16 } = useTokenBalance(getbtntyAddress())
  const { balance: cakeBalance17 } = useTokenBalance(getltcAddress())
  const { balance: cakeBalance18 } = useTokenBalance(getethAddress())
  const { balance: cakeBalance19 } = useTokenBalance(getetcAddress())
  const { balance: cakeBalance1X } = useTokenBalance(getFarm2Address())
  const { balance: cakeBalance20 } = useTokenBalance(getbabyAddress())
  const { balance: cakeBalance21 } = useTokenBalance(getbtcAddress())
    const { balance: cakeBalance22 } = useTokenBalance(getcubyAddress())
    const { balance: cakeBalance23 } = useTokenBalance(getFRTCAddress())
    const { balance: cakeBalance24 } = useTokenBalance(getHmttAddress())


  const { balance: cakeBalance2X } = useTokenBalance(getFarm1Address())
  const { balance: cakeBalance3X } = useTokenBalance(getFarm3Address())
  const { balance: cakeBalance4X } = useTokenBalance(getFarm4Address())
  const { balance: cakeBalance5X } = useTokenBalance(getFarm5Address())
  const { balance: cakeBalance6X } = useTokenBalance(getFarm6Address())
  const { balance: cakeBalance7X } = useTokenBalance(getFarm7Address())
  const { balance: cakeBalance8X } = useTokenBalance(getFarm8Address())
  const { balance: cakeBalance9X } = useTokenBalance(getFarm9Address())
  const { balance: cakeBalance10X } = useTokenBalance(getFarm10Address())
  const { balance: cakeBalance11X } = useTokenBalance(getFarm11Address())
  const { balance: cakeBalance12X } = useTokenBalance(getFarm12Address())
  const { balance: cakeBalance13X } = useTokenBalance(getFarm13Address())
  const { balance: cakeBalance14X } = useTokenBalance(getFarm14Address())
  const { balance: cakeBalance15X } = useTokenBalance(getFarm15Address())
  const { balance: cakeBalance16X } = useTokenBalance(getFarm16Address())
  const { balance: cakeBalance17X } = useTokenBalance(getFarm17Address())
  const { balance: cakeBalance18X } = useTokenBalance(getFarm18Address())
  const { balance: cakeBalance19X } = useTokenBalance(getFarm19Address())
  const { balance: cakeBalance20X } = useTokenBalance(getFarm20Address())
  const { balance: cakeBalance22X } = useTokenBalance(getFarm22Address())
  const { balance: cakeBalance23X } = useTokenBalance(getFarm23Address())
  const { balance: cakeBalance24X } = useTokenBalance(getFarm24Address())
  const { balance: cakeBalance25X } = useTokenBalance(getFarm25Address())
  const { balance: cakeBalance26X } = useTokenBalance(getFarm26Address())
  const { balance: cakeBalance27X } = useTokenBalance(getFarm27Address())

  const { balance: cakeBalanceN } = useTokenBalance(getVNAddress())



  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE2)
  const hasLowGodBalance = fetchStatus === FetchStatus.SUCCESS && balance.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod2Balance = cakeBalance.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod3Balance = cakeBalance2.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod4Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod5Balance = cakeBalance4.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod6Balance = cakeBalance5.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod7Balance = cakeBalance6.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod8Balance = cakeBalance7.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod9Balance = cakeBalance8.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGod10Balance = cakeBalance9.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance11 = cakeBalance10.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance12 = cakeBalance11.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance13 = cakeBalance12.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance14 = cakeBalance13.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance15 = cakeBalance14.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance16 = cakeBalance15.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance17 = cakeBalance16.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance18 = cakeBalance17.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance19 = cakeBalance18.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance20 = cakeBalance19.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance1X = cakeBalance1X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance21 = cakeBalance20.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance22 = cakeBalance21.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance23 = cakeBalance22.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance24 = cakeBalance23.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance25 = cakeBalance24.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)

  const hasLowGodBalance2X = cakeBalance2X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance3X = cakeBalance3X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance4X = cakeBalance4X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance5X = cakeBalance5X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance6X = cakeBalance6X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance7X = cakeBalance7X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance8X = cakeBalance8X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance9X = cakeBalance9X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance10X = cakeBalance10X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance11X = cakeBalance11X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance12X = cakeBalance12X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance13X = cakeBalance13X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance14X = cakeBalance14X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance15X = cakeBalance15X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance16X = cakeBalance16X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance17X = cakeBalance17X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance18X = cakeBalance18X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance19X = cakeBalance19X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance20X = cakeBalance20X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance22X = cakeBalance22X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance23X = cakeBalance23X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance24X = cakeBalance24X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance25X = cakeBalance25X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance26X = cakeBalance26X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const hasLowGodBalance27X = cakeBalance27X.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)

  const hasLowGodBalanceN = cakeBalanceN.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)


  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <ModalContainer title={t('Welcome!')} minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Your Wallet')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        {view === WalletView2.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} hasLowGodBalance21={hasLowGodBalance21} hasLowGodBalance23={hasLowGodBalance23} hasLowGodBalance25={hasLowGodBalance25} hasLowGodBalance24={hasLowGodBalance24} hasLowGodBalance22={hasLowGodBalance22} hasLowGodBalance1X={hasLowGodBalance1X} hasLowGodBalanceN={hasLowGodBalanceN} hasLowGodBalance={hasLowGodBalance} hasLowGod2Balance={hasLowGod2Balance} hasLowGod3Balance={hasLowGod3Balance}  hasLowGod4Balance={hasLowGod4Balance} hasLowGod5Balance={hasLowGod5Balance}  hasLowGod6Balance={hasLowGod6Balance} hasLowGod7Balance={hasLowGod7Balance}  hasLowGod8Balance={hasLowGod8Balance} hasLowGod9Balance={hasLowGod9Balance} hasLowGod10Balance={hasLowGod10Balance} hasLowGodBalance11={hasLowGodBalance11} hasLowGodBalance12={hasLowGodBalance12} hasLowGodBalance13={hasLowGodBalance13} hasLowGodBalance14={hasLowGodBalance14} hasLowGodBalance15={hasLowGodBalance15}  hasLowGodBalance16={hasLowGodBalance16} hasLowGodBalance17={hasLowGodBalance17} hasLowGodBalance18={hasLowGodBalance18} hasLowGodBalance19={hasLowGodBalance19} hasLowGodBalance20={hasLowGodBalance20} onDismiss={onDismiss} />}
        {view === WalletView2.TRANSACTIONS && <WalletTransactions />}
      </ModalBody>
    </ModalContainer>
  )
}

export default WalletModal2
