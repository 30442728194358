/** @jsxImportSource theme-ui */
import React from 'react'
import styled from 'styled-components'
import { Flex, Image } from '@pancakeswap/uikit'
import Page from 'views/PageAddFiat'
import MoonPayIframe from './MoonFrame'

const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;

}
`
const Nem = () => {
  return (
    <Page> 
          <Flex mb="35px"> 
        <MoonPayIframe />  
        </Flex> 
        
        </Page> 
  )
}

export default Nem