import {
  EIP1193Options,
  currentProvider,
  getDownload,
  getIsInstall,
  installWalletMessage
} from '@bitget-wallet/web3-sdk'
import type {
  Actions,
  AddEthereumChainParameter,
  Provider,
  ProviderConnectInfo,
  ProviderRpcError,
  WatchAssetParameters,
} from '@web3-react/types'
import { Connector } from '@web3-react/types'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core/core/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { ChainId } from '@ape.swap/sdk'
import { RPC_URLS } from 'config/constants/networks2'
import { RPC_PROVIDERS } from 'config/constants/providers'
import { MAINNET_CHAINS } from 'config/constants/chains'  
import { Connection, ConnectionType } from './types'

type BitgetWalletProvider = Provider & {
  isBitkeep?: boolean
  isConnected?: () => boolean
  providers?: BitgetWalletProvider[]
  get chainId(): string
  get accounts(): string[]
}

export class NoBitgetWalletError extends Error {
  public constructor() {
    super('BitgetWallet not installed')
    this.name = NoBitgetWalletError.name
    Object.setPrototypeOf(this, NoBitgetWalletError.prototype)
  }
}

type BitgetWalletConnectorOptions = EIP1193Options & {
  shimDisconnect?: boolean
}

function parseChainId(chainId: string) {
  return Number.parseInt(chainId, 16)
}

export interface BitgetWalletConstructorArgs {
  actions: Actions
  options?: BitgetWalletConnectorOptions
  onError?: (error: Error) => void
}

export class BitgetWallet extends Connector {
  public provider?: BitgetWalletProvider;

  private readonly options?: BitgetWalletConnectorOptions;

  constructor({ actions, options, onError }: BitgetWalletConstructorArgs) {
    super(actions, onError);
    this.options = options;
  }

  public static async selectInjectedProvider(walletType: 'MetaMask' | 'TrustWallet'): Promise<BitgetWalletProvider | undefined> {
    const ethereum = (window as any).ethereum;
    if (!ethereum) {
        console.warn('Nincs ethereum objektum');
        return undefined;
    }

    let selectedProvider: BitgetWalletProvider | undefined;

    // Ha több provider van, az Array.prototype.find metódust használjuk a megfelelő provider kiválasztásához
    if (Array.isArray(ethereum.providers)) {
        selectedProvider = ethereum.providers.find(provider =>
            (walletType === 'MetaMask' && provider.isMetaMask) ||
            (walletType === 'TrustWallet' && (provider.isTrust || provider.isTrustWallet))
        );
    }

    // Ha a provider nem található a tömbben, ellenőrizzük az egyetlen provider szcenáriót
    if (!selectedProvider && walletType === 'MetaMask' && ethereum.isMetaMask) {
        selectedProvider = ethereum;
    } else if (!selectedProvider && walletType === 'TrustWallet' && (ethereum.isTrust || ethereum.isTrustWallet)) {
        selectedProvider = ethereum;
    }

    if (selectedProvider) {
        try {
            const accounts: unknown = await selectedProvider.request({ method: 'eth_requestAccounts' });
            if (Array.isArray(accounts) && accounts.length > 0) {
                return selectedProvider;
            }
        } catch (error) {
            console.warn(`${walletType} csatlakozási hiba:`, JSON.stringify(error, null, 2));
        }
    } else {
        console.warn(`${walletType} provider nem található`);
    }

    return undefined;
}

public static async connectToMetaMask(): Promise<BitgetWalletProvider | undefined> {
    const ethereum = (window as any).ethereum;
    if (!ethereum || !ethereum.isMetaMask) {
        return undefined;
    }

    try {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        if (Array.isArray(accounts) && accounts.length > 0) {
            return ethereum as BitgetWalletProvider;
        }
    } catch (error) {
        console.warn('MetaMask csatlakozási hiba:', JSON.stringify(error, null, 2));
    }

    return undefined;
}

public static async connectToTrustWallet(): Promise<BitgetWalletProvider | undefined> {
    const ethereum = (window as any).ethereum;
    if (!ethereum || !ethereum.isTrust) {
        return undefined;
    }

    try {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        if (Array.isArray(accounts) && accounts.length > 0) {
            return ethereum as BitgetWalletProvider;
        }
    } catch (error) {
        console.warn('Trust Wallet csatlakozási hiba:', JSON.stringify(error, null, 2));
    } 

    return undefined;
}


  private async isomorphicInitialize(): Promise<void> {
    let provider = await BitgetWallet.connectToMetaMask();

    if (!provider) {
      provider = await BitgetWallet.connectToTrustWallet();
    }

    if (provider) {
      this.provider = provider as BitgetWalletProvider;
      this.provider.on('connect', ({ chainId }: ProviderConnectInfo): void => {
        this.actions.update({ chainId: parseChainId(chainId) });
      });

      this.provider.on('disconnect', (error: ProviderRpcError): void => {
        if (error.code === 1013) {
          console.debug('BitgetWallet logged connection error 1013: "Try again later"');
          return;
        }
        this.actions.resetState();
        this.onError?.(error);
      });

      this.provider.on('chainChanged', (chainId: string): void => {
        this.actions.update({ chainId: parseChainId(chainId) });
      });

      this.provider.on('accountsChanged', (accounts: string[]): void => {
        if (accounts.length === 0) {
          this.actions.resetState();
        } else {
          this.actions.update({ accounts });
        }
      });
    } else {
      window.open(getDownload(), '_blank');
      throw new Error(installWalletMessage);
    }
  }

  public async connectEagerly(): Promise<void | undefined> {
    const cancelActivation = this.actions.startActivation();

    try {
      await this.isomorphicInitialize();
      if (!this.provider) {
        cancelActivation();
        return;
      }

      const [accounts, chainId] = await Promise.all([
        this.provider.request({ method: 'eth_accounts' }) as Promise<string[]>,
        this.provider.request({ method: 'eth_chainId' }) as Promise<string>,
      ]);

      if (!accounts.length) throw new Error('No accounts returned');
      this.actions.update({ chainId: parseChainId(chainId), accounts });

      return;  // Sikeres futás esetén visszatérünk
    } catch (error) {
      console.debug('Could not connect eagerly', error);
      this.actions.resetState();
      throw error;
    }
  }

  public async activate(desiredChainIdOrChainParameters?: number | AddEthereumChainParameter): Promise<void> {
    let cancelActivation: () => void;
    if (!this.provider?.isConnected?.()) cancelActivation = this.actions.startActivation();

    try {
      await this.isomorphicInitialize();
      if (!this.provider) throw new NoBitgetWalletError();

      const [accounts, chainId] = await Promise.all([
        this.provider.request({ method: 'eth_requestAccounts' }) as Promise<string[]>,
        this.provider.request({ method: 'eth_chainId' }) as Promise<string>,
      ]);

      const receivedChainId = parseChainId(chainId);
      const desiredChainId =
        typeof desiredChainIdOrChainParameters === 'number'
          ? desiredChainIdOrChainParameters
          : desiredChainIdOrChainParameters?.chainId;

      if (!desiredChainId || receivedChainId === desiredChainId) {
        this.actions.update({ chainId: receivedChainId, accounts });
        return;
      } 

      const desiredChainIdHex = `0x${desiredChainId.toString(16)}`;

      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: desiredChainIdHex }],
      }).catch((error: ProviderRpcError) => {
        const errorCode = (error.data as any)?.originalError?.code || error.code;
        if (errorCode === 4902 && typeof desiredChainIdOrChainParameters !== 'number') {
          if (!this.provider) throw new Error('No provider');
          return this.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{ ...desiredChainIdOrChainParameters, chainId: desiredChainIdHex }],
          });
        }
        throw error;
      });

      await this.activate(desiredChainId);
    } catch (error) {
      cancelActivation?.();
      throw error;
    }
  }

  public async watchAsset({ address, symbol, decimals, image }: WatchAssetParameters): Promise<true> {
    if (!this.provider) throw new Error('No provider')

    return this.provider
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
            image, // A string url of the token logo
          },
        },
      })
      .then((success) => {
        if (!success) throw new Error('Rejected')
        return true
      })
  }
}

// Connections based from uniswap interface 
const [mainnet, ...optionalChains] = MAINNET_CHAINS

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      urlMap: RPC_PROVIDERS,
      defaultChainId: ChainId.BSC,
    }),
)

export const networkConnection: Connection = {
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
}

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions, onError }))

export const injectedConnection: Connection = {
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
}

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>((actions) => new GnosisSafe({ actions }))

export const gnosisSafeConnection: Connection = {
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
}

export const [web3WalletConnectV2, web3WalletConnectHooksV2] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId:'ae3e4720b890d1efc8745f90a2237732',
        chains: [mainnet],
        optionalChains,  
        showQrModal: true,
      },  
      onError,
    }),
)

const [web3Bitget, web3BitgetHooks] = initializeConnector<BitgetWallet>(
  (actions) =>
    new BitgetWallet({
      actions,
      options: {
        // Az időtúllépés (milliszekundumban), ha a kapcsolódás túl hosszú ideig tart
      },
      onError,
    }),
);

export const walletConnectConnection: Connection = {
  connector: web3WalletConnectV2,
  hooks: web3WalletConnectHooksV2,
  type: ConnectionType.WALLET_CONNECT,
}

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[ChainId.BSC][0],
        appName: 'ApeSwap',
        appLogoUrl: 'favicon.ico',
        reloadOnDisconnect: false,
      },
      onError,
    }),
)

export const coinbaseWalletConnection: Connection = {
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
}

export const bitgetConnectConnection: Connection = {
  connector: web3Bitget,
  hooks: web3BitgetHooks,
  type: ConnectionType.BitgetConnect,
};

// Order this list for wallet display preferences
export const listOfConnections: Connection[] = [
  injectedConnection,
  walletConnectConnection,
  coinbaseWalletConnection,
  gnosisSafeConnection,
  networkConnection,
  bitgetConnectConnection, // BitgetConnect hozzáadása a listához
]

export const connectionByType: Record<ConnectionType, Connection> = {
  [ConnectionType.INJECTED]: injectedConnection,
  [ConnectionType.COINBASE_WALLET]: coinbaseWalletConnection,
  [ConnectionType.WALLET_CONNECT]: walletConnectConnection,
  [ConnectionType.GNOSIS_SAFE]: gnosisSafeConnection,
  [ConnectionType.NETWORK]: networkConnection,
  [ConnectionType.BitgetConnect]: bitgetConnectConnection,
}
