import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const StyledCard = styled(Card)<{ isFinished?: boolean }>`
  max-width: 250px;
  min-width: 250px;
  margin: 0 8px 24px;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  position: relative;
   background-color: rgb(10,20,20);
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 250px;
    min-width: 250px;
    margin: 0 12px 46px;
    background-color: rgb(10,20,20);
y  }
`

export default StyledCard
