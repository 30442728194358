import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: 'VIZSLASWAP',
    lpSymbol1:'0',
    lpSymbol2: '',
    lpSymbol3: '',
    image:'0A',
    lpAddresses: {
      97: '',
      56: '0xadaae082237cb1772c9e079db95c117e6dd0c5af',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'VIZSLASWAP-BNB LP',
    lpSymbol1:'1',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'1A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x8d3cF6F026f1c8021BD3B0F74263D4D51208917F',
    },
    token: tokens.vizslaswap,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 18,
    lpSymbol: 'VIZSLASWAP-BNB LP',
    lpSymbol2: '(APESWAP)',
    lpSymbol3: 'apeliquidity',
    lpSymbol1:'1',
    image:'1A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x51C1EbAFCCcF4A8e811E1fFE164731C874417AC3',
    },
    token: tokens.vizslaswap,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 49,
    lpSymbol: 'HVI-BNB LP',
    lpSymbol2: '(APESWAP)',
    lpSymbol3: 'apeliquidity',
    lpSymbol1:'3',
    image:'3A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xF27312EeBD243dceb1856972deA340929a7Dbf91',
    },
    token: tokens.hvi,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 8,
    lpSymbol: 'VIZSLASWAP-BUSD LP',
    lpSymbol1:'8',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'8A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x738156eAE2715776CBF92eD5751F7b8fc99951b7',
    },
    token: tokens.vizslaswap,
    quoteToken: tokens.busd,
   
   },
   {
    pid: 20,
    lpSymbol: 'VIZSLASWAP-BUSD LP',
    lpSymbol1:'8',
    lpSymbol2: '(RADIOSHACK)',
    lpSymbol3: 'radioliquidity',
    image:'8A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xC0f5FAAc9CF6bdE571aFAd1915eF183D8996CDb8',
    },
    token: tokens.vizslaswap,
    quoteToken: tokens.busd,
   },
   {
    pid: 3,
    lpSymbol: 'HVI-BNB LP',
    lpSymbol1:'3',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'3A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x0388cc967e382A1c85161453bc6cEaA70E59E33e',
    },
    token: tokens.hvi,
    quoteToken: tokens.wbnb,
   
   },
   {
    pid: 5,
    lpSymbol: 'HVI-BUSD LP',
    lpSymbol1:'5',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'5A',
    isEvent: true,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x8F71caaB0e4A0eB8615aBfA6A12996cC3d37e988',
    },
    token: tokens.hvi,
    quoteToken: tokens.busd,
   
   },
   {
    pid: 36,
    lpSymbol: 'HVI-VIZSLASWAP LP',
    lpSymbol1:'36',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'36A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x687351B70e02AAF7407D3a33f0032eC733C68738',
    },
    token: tokens.hvi,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 50,
    lpSymbol: 'BetHustlers-VIZSLASWAP LP',
    lpSymbol1:'50',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'50A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x0aFb79195368Ea93BF70F094193f1B3B4047F1DE',
    },
    token: tokens.bethustlers,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 46,
    lpSymbol: 'FartCoin-VIZSLASWAP LP',
    lpSymbol1:'46',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'46A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x54DAD5444FbcFb7569dB7E7Aa14e9298F1054dC2',
    },
    token: tokens.FartCoin,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 41,
    lpSymbol: 'BABY-BNB LP',
    lpSymbol1:'41',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'41A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x6c98A19937068624275CC505e28F91Fc36E2AcD1',
    },
    token: tokens.baby,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 42,
    lpSymbol: 'BABY-VIZSLASWAP LP',
    lpSymbol1:'42',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'42A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x1FB9d4b70E56dd6611F85968b7E089A310Cd3f8c',
    },
    token: tokens.baby,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 43,
    lpSymbol: 'USDT-BNB LP',
    lpSymbol1:'43',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'43A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 40,
    lpSymbol: 'LGC-VIZSLASWAP LP',
    lpSymbol1:'40',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'40A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x06979dF1cf9Af043EcEA997797071fA646AbaA57',
    },
    token: tokens.lgc,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 39,
    lpSymbol: 'LGC-BNB LP',
    lpSymbol1:'39',
    image:'39A',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x9e217b6BBee64D367535250Af62d53Bf657570B4',
    },
    token: tokens.lgc,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 27,
    lpSymbol: 'LAMEA-VIZSLASWAP LP',
    lpSymbol1:'27',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'27A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x6140A034d185a0a9Bbb8afD4E3F92BCB0c69c67a',
    },
    token: tokens.lamea,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 38,
    lpSymbol: 'LIRA-VIZSLASWAP LP',
    lpSymbol1:'38',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'38A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x097D1cc7555Cb3D9325B8c95BCa0F3caa47a7925',
    },
    token: tokens.lira,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 37,
    lpSymbol: 'LIRA-BNB LP',
    lpSymbol1:'37',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'37A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x8428786e4B806CD803c47E4d284aB41cBF94B937',
    },
    token: tokens.lira,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 28,
    lpSymbol: 'BVC-VIZSLASWAP LP',
    lpSymbol1:'28',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'28A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x189426C386C446Df3549e3B7Af9bF5D1321E30B9',
    },
    token: tokens.bvc,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 29,
    lpSymbol: 'BABYVIZSLA-VIZSLASWAP LP',
    lpSymbol1:'29',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'29A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x58d30bA21b9Edb7c3526F4539319bEC3A7F23944',
    },
    token: tokens.babyvizsla,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 30,
    lpSymbol: 'FPS-VIZSLASWAP LP',
    lpSymbol1:'30',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'30A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xAC79F8f638649660b3D7B62aCf40Ef7dD65B35dF',
    },
    token: tokens.fps,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 31,
    lpSymbol: 'SKELETON-VIZSLASWAP LP',
    lpSymbol1:'31',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'31A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xD91AD40D8ff1F0405ce10230D214eD79fDa73d61',
    },
    token: tokens.skeleton,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 32,
    lpSymbol: 'BUILD-VIZSLASWAP LP',
    lpSymbol1:'32',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'32A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x8a9d56706950495cf2CF1799E311f9c68C81D473',
    },
    token: tokens.build,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 33,
    lpSymbol: 'ETH-VIZSLASWAP LP',
    lpSymbol1:'33',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'33A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x48eE61775B6E737a87b38BaC01faC55281ee0B4a',
    },
    token: tokens.eth,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 34,
    lpSymbol: 'ATALIRA-VIZSLASWAP LP',
    lpSymbol1:'34',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'34A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xD6d0DF1FF1B26c4Aed6a51fd58030ba6EF06e9f5',
    },
    token: tokens.atalira,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 35,
    lpSymbol: 'EXZO-VIZSLASWAP LP',
    lpSymbol1:'35',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'35A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xC7f86B54BA7eE572b466Af28e3100780736c5934',
    },
    token: tokens.exzo,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 19,
    lpSymbol: 'LAMEA-BNB LP',
    lpSymbol1:'19',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'19A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x20a9b426c8C7ccbddF8e9d8950966DE192Ce393b',
    },
    token: tokens.lamea,
    quoteToken: tokens.wbnb,
   
   },
   {
    pid: 4,
    lpSymbol: 'BVC-BNB LP',
    lpSymbol1:'4',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'4A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x7b338aB42d823e50851f3C61d8374D83c4E0F71f',
    },
    token: tokens.bvc,
    quoteToken: tokens.wbnb,
   
   },
   {
    pid: 6,
    lpSymbol: 'BABYVIZSLA-BNB LP',
    lpSymbol1:'6',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'6A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xeb94c1EA54AD6367D414B0B2075950646D0B6266',
    },
    token: tokens.babyvizsla,
    quoteToken: tokens.wbnb,
   
   },
   {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpSymbol1:'2',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'2A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
   
   },
   {
    pid: 9,
    lpSymbol: 'ETH-BNB LP',
    lpSymbol1:'9',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'9A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 10,
    lpSymbol: 'LTC-BNB LP',
    lpSymbol1:'10',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'10A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC',
    },
    token: tokens.ltc,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 11,
    lpSymbol: 'FPS-BUSD LP',
    lpSymbol1:'11',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'11A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x91F1Ae54D0c028C5f4dD428231935b408e331682',
    },
    token: tokens.fps,
    quoteToken: tokens.busd,
   },
   {
    pid: 12,
    lpSymbol: 'SKELETON-BNB LP',
    lpSymbol1:'12',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'12A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xB44c8314c5AFEbADE2d895Ea2079452a602619c6',
    },
    token: tokens.skeleton,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 13,
    lpSymbol: 'AMPLE-BNB LP',
    lpSymbol1:'13',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'13A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x057f4e9cDcDD09cde25Fa3DcAaF4166eB02F4F0e',
    },
    token: tokens.ample,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 14,
    lpSymbol: 'SHIB-BNB LP',
    lpSymbol1:'14',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'14A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x6b7b3523a6660a5fcE3c28E1536CC8dd8D57f7E0',
    },
    token: tokens.shib,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 15,
    lpSymbol: 'HANK-BNB LP',
    lpSymbol1:'15',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'15A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x16B84784B13183c55e57C9F586697a369eBCF367',
    },
    token: tokens.hank,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 16,
    lpSymbol: 'ACE-BNB LP',
    lpSymbol1:'16',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'16A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x2f9916c808b3F7687d51880aeda629F61051e4Ef',
    },
    token: tokens.ace,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 17,
    lpSymbol: 'ETC-BNB LP',
    lpSymbol1:'17',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'17A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x4d2d10B785BF000Dfd19e92cE2D2aE79E119b956',
    },
    token: tokens.etc,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 21,
    lpSymbol: 'ATALIRA-BNB LP',
    lpSymbol1:'21',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'21A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x92AB1792235208d119Eee8A420ec264687F4BFC9',
    },
    token: tokens.atalira,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 22,
    lpSymbol: 'EXZO-BNB LP',
    lpSymbol1:'22',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'22A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x31e4286de26215c2e790732Dc8EC78Af36c338a7',
    },
    token: tokens.exzo,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 23,
    lpSymbol: 'BUILD-BNB LP',
    lpSymbol1:'23',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'23A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xEacB9B9c341988d4397F6E1627B2fF32581a2842',
    },
    token: tokens.build,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 24,
    lpSymbol: 'BTNTY-BNB LP',
    lpSymbol1:'24',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'24A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xfF2FFa2B8B59471dD8315c652c4431589DF454ba',
    },
    token: tokens.btnty,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 25,
    lpSymbol: 'TRIP-BNB LP',
    lpSymbol1:'25',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'25A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x109c9Cf64f8AE7Ff01D32597508A9407eBd4dF6f',
    },
    token: tokens.trip,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 26,
    lpSymbol: 'TRIP-VIZSLASWAP LP',
    lpSymbol1:'26',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'26A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x1b0169f8b912f7AD2B5000eB6722A987660bd365',
    },
    token: tokens.trip,
    quoteToken: tokens.vizslaswap,
   },
   {
    pid: 44,
    lpSymbol: 'BTCB-BNB LP',
    lpSymbol1:'44',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'44A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 45,
    lpSymbol: 'CUBY-BNB LP',
    lpSymbol1:'45',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'45A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0x73b83F83DF3438CDAc390CE0227BDeDa47dBb584',
    },
    token: tokens.cuby,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 51,
    lpSymbol: 'BetHustlers-BNB LP',
    lpSymbol1:'51',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'51A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xc49627586057cd1126edcfb2af6d65b31bc8395b',
    },
    token: tokens.bethustlers,
    quoteToken: tokens.wbnb,
   },
   {
    pid: 47,
    lpSymbol: 'FartCoin-BNB LP',
    lpSymbol1:'47',
    lpSymbol2: '(PANCAKESWAP)',
    lpSymbol3: '',
    image:'47A',
    isEvent: false,
    isCommunity: false,
    lpAddresses: {
      97: '',
      56: '0xAcB8577021fbA4be7666C561202615C2A0eB5BD9',
    },
    token: tokens.FartCoin,
    quoteToken: tokens.wbnb,
   }

  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
   
]

export default farms

