import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Text} from '@pancakeswap/uikit';
import { useFarm23, useFarm25, useFarm7, useFarm8, useTotalSupplyFarm23, useTotalSupplyFarm25, useTotalSupplyFarm7, useTotalSupplyFarm8 } from 'hooks/useTokenBalance';
import { getBalanceNumber } from 'utils/formatBalance';
import { getCakeAddress, getHVIAddress, getLAMEAAddress, getlgcAddress } from 'utils/addressHelpers';
import { usePriceBABYVIZSLABusd, usePricebeethBusd, usePriceCakeBusd, usePriceETCBusd, usePriceETHBusd, usePriceHVIBusd, usePriceLameaBusd, usePriceLGCBusd, usePriceLTCBusd, usePriceSHIBBusd } from 'state/farms/hooks';
import { useWeb3React } from '@web3-react/core/core/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ethers } from 'ethers';
import FarmsPoolsRowLoader from '../Home/components/FarmsPoolsRowLoader';

const Container = styled.div`
  padding: 20px; 
  max-width: 800px; 
  margin: auto;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Title = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
`;

const TokenList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenCard = styled.div<{ isSelected: boolean }>`
  margin: 10px 0;  
  padding: 15px;
  background: ${({ theme, isSelected }) => isSelected ? '#282c34' : theme.colors.background};
  border-radius: 12px;
  box-shadow: ${({ isSelected }) => isSelected ? '0 12px 24px rgba(0, 0, 0, 0.4)' : '0 6px 12px rgba(0, 0, 0, 0.2)'};
  display: flex;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    background: #333;
  }
`;

const TokenImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

const TokenDetails = styled.div`
  flex: 1;
`;

const TokenLabel = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`;

const TokenAddress = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;
`;

const TokenValue = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  margin: 5px 0 0;
`;

const TotalValue = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.text};
`;

const StyledButton = styled(Button)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.2s, transform 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    transform: scale(1.05);
  }
`;

const Arrow = styled.span<{ direction: 'up' | 'down' }>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-${({ direction }) => direction}: 6px solid ${({ theme }) => theme.colors.background};
  margin-left: 8px;
`;

const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
];

const POOL_ABI = [
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_user",
        "type":"address"
      }
    ],
    "name":"pendingReward",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  }
];
  

const STAKING_CONTRACT_ABI_WITH_PID = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_pid",
        "type": "uint256"
      },
      { 
        "internalType": "address",
        "name": "_user",
        "type": "address"
      }
    ],
    "name": "pendingCake",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

const TOKEN_ADDRESSES = {
  VIZSLASWAP: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF', 
  VIZSLASWAPBUSDLP: '0xC0f5FAAc9CF6bdE571aFAd1915eF183D8996CDb8', // pid20
  VIZSLASWAPBUSDLPPANCAKESWAP: '0x738156eAE2715776CBF92eD5751F7b8fc99951b7', // pid8
  VIZSLASWAPBNBLPPANCAKESWAP: '0x8d3cF6F026f1c8021BD3B0F74263D4D51208917F', // pid1
  VIZSLASWAPBNBLPAPESWAP: '0x51C1EbAFCCcF4A8e811E1fFE164731C874417AC3', // pid18
  HVIBNBLPPANCAKESWAP: '0x0388cc967e382A1c85161453bc6cEaA70E59E33e', // pid3
  HVIBNBLPAPESWAP: '0xF27312EeBD243dceb1856972deA340929a7Dbf91', // pid49
  HVIBUSDLP: '0x8F71caaB0e4A0eB8615aBfA6A12996cC3d37e988', // pid5
  BVCVIZSLASWAPLP: '0x189426C386C446Df3549e3B7Af9bF5D1321E30B9', // pid28
  BABYVIZSLAVIZSLASWAPLP: '0x58d30bA21b9Edb7c3526F4539319bEC3A7F23944', // pid29
  BABYVIZSLABNBLP: '0xeb94c1EA54AD6367D414B0B2075950646D0B6266', // pid6
  HVIVIZSLASWAPLP: '0x687351B70e02AAF7407D3a33f0032eC733C68738', // pid36
  BETHUSTLERSVIZSLASWAPLP: '0x0aFb79195368Ea93BF70F094193f1B3B4047F1DE', // pid50
  LGC: '0x3496212eC43Cc49F5151ec4405efD4975e036f89', // lgc
  BABYVIZSLA: '0xfB19F247F1f22Cef1F0384535C779c664632cB6F', // babyvizsla
  VIZSLASWAPPOOLS: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF', // vizslapools
  HVI: '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26', // hvi
  BETHUSTLERS: '0xE2B1f3908588e2338587ED998F974EE6F63f500d', // BETHUSTLER
  LAMEA: '0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3', // LAMEA
  ETC: '0x3d6545b08693daE087E957cb1180ee38B9e3c25E', // ETC
  SHIB: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D', // BETHUSTLER
  LTC: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94', // LAMEA
  ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', // ETC
  VIZSLASWAPLAMEALP: '0x6140a034d185a0a9bbb8afd4e3f92bcb0c69c67a', // LAMEA
  HVILGCLP: '0xD41A8b2A8366AbdbF31FeDba8e5F6E13Ec959Ba5', // LAMEA
  VIZSLASWAPLGCLP: '0x06979dF1cf9Af043EcEA997797071fA646AbaA57', // LAMEA
  VIZSLASWAPHVILP: '0x687351B70e02AAF7407D3a33f0032eC733C68738', // LAMEA
};

const getTokenImageSrc = (tokenAddress: string) => {
  return `images/wallet/${tokenAddress}.png`; // Ensure the path and filename convention match your assets
};

const User2: React.FC = () => {
  const [balances, setBalances] = useState<{ [key: string]: number }>({});
  const { account } = useWeb3React()
  const [values, setValues] = useState<{ [key: string]: number }>({});
  const [bnbBalance, setBnbBalance] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<string | null>(null);
  const [notAutoVizslaSwapBalance, setNotAutoVizslaSwapBalance] = useState<number>(0);
  const [vizslaswapbusdlpBalance, setvizslaswapbusdlp] = useState<number>(0);
  const [vizslaswapbusdlppancakeswapBalance, setvizslaswapbusdlppancakeswap] = useState<number>(0);
  const [vizslaswapbnblppancakeswapBalance, setvizslaswapbnblppanacakeswap] = useState<number>(0);
  const [vizslaswapbnblpapeswapBalance, setvizslaswapbnblpapeswap] = useState<number>(0);
  const [hvibnblppancakeswapBalance, sethvibnblppancakeswap] = useState<number>(0);
  const [hvibnblpapeswapBalance, sethvibnblpapeswap] = useState<number>(0);
  const [hvibusdlpBalance, sethvibusdlp] = useState<number>(0);
  const [bvcvizslaswaplpBalance, setbvcvizslaswaplp] = useState<number>(0);
  const [babyvizslavizslaswaplpBalance, setbabyvizslavizslaswaplp] = useState<number>(0);
  const [babyvizslabnblpBalance, setbabyvizslabnblp] = useState<number>(0);
  const [hvivizslaswaplpBalance, sethvivizslaswaplp] = useState<number>(0);
  const [bethustlersvizslaswaplpBalance, setbethustlervizslaswaplp] = useState<number>(0);
  const [poolLGCBalance, setpoolLGC] = useState<number>(0);
  const [poolLGCBalance2, setpoolLGC2] = useState<number>(0);
  const [poolLGCBalance3, setpoolLGC3] = useState<number>(0);
  const [poolBabyvizslaBalance, setpoolBabyvizsla] = useState<number>(0);
  const [poolBabyvizslaBalance2, setpoolBabyvizsla2] = useState<number>(0);
  const [poolBabyvizslaBalance3, setpoolBabyvizsla3] = useState<number>(0);
  const [poolhviBalance, setpoolhvi] = useState<number>(0);
  const [poolhviBalance2, setpoolhvi2] = useState<number>(0);
  const [poolhviBalance3, setpoolhvi3] = useState<number>(0);
  const [poolvizslaBalance, setpoolvizsla] = useState<number>(0);
  const [poolvizslaBalance2, setpoolvizsla2] = useState<number>(0);
  const [poolbethBalance, setpoolbeth] = useState<number>(0);
  const [poolbethBalance2, setpoolbeth2] = useState<number>(0);
  const [poolbethBalance3, setpoolbeth3] = useState<number>(0);
  const [poollameaBalance, setpoollamea] = useState<number>(0);
  const [poollameaBalance2, setpoollamea2] = useState<number>(0);
  const [pooletcBalance, setpooletc] = useState<number>(0);
  const [pooletcBalance2, setpooletc2] = useState<number>(0);
  const [poolshibBalance, setpoolshib] = useState<number>(0);
  const [poolltcBalance, setpoolltc] = useState<number>(0);
  const [poolethBalance, setpooleth] = useState<number>(0);
  const [poolvizslaswaplameaBalance, setpoolvizslaswaplamea] = useState<number>(0);
  const [poollgchviBalance, setpoollgchvi] = useState<number>(0);
  const [poolvizslaswaplgcBalance, setpoolvizslaswaplgc] = useState<number>(0);
  const [poolvizslaswaphvi, setpoolvizslaswaphvi] = useState<number>(0);

  const priceCake = usePriceCakeBusd().toNumber();
  const priceLGC = usePriceLGCBusd().toNumber();
  const priceBabyvizsla = usePriceBABYVIZSLABusd().toNumber();
  const pricehvi = usePriceHVIBusd().toNumber();
  const pricebeth = usePricebeethBusd().toNumber();
  const pricelamea = usePriceLameaBusd().toNumber();
  const priceetc = usePriceETCBusd().toNumber();
  const priceshib = usePriceSHIBBusd().toNumber();
  const priceltc = usePriceLTCBusd().toNumber();
  const priceeth = usePriceETHBusd().toNumber();

// lameavizsla lp

const lamealpbalance = getBalanceNumber(useFarm8(getLAMEAAddress()))
  const vizslalpbalance6 = getBalanceNumber(useFarm8(getCakeAddress()))
  const kombo15 = useTotalSupplyFarm8()
  const kombo16 = kombo15 ? getBalanceNumber(kombo15) : 0
  const teljes22 = pricelamea * lamealpbalance
  const teljes23 = priceCake * vizslalpbalance6
  const teljes24 = teljes22 + teljes23
  const minden1 = teljes24 / kombo16
 
  const vizslalpbalance19 = getBalanceNumber(useFarm23(getCakeAddress()))
  const lgclpbalance = getBalanceNumber(useFarm23(getlgcAddress()))
  const kombo45 = useTotalSupplyFarm23()
  const kombo46 = kombo45 ? getBalanceNumber(kombo45) : 0 
  const teljes67 = priceCake * vizslalpbalance19
  const teljes68 = priceLGC * lgclpbalance
  const teljes69 = teljes67 + teljes68
  const minden2 = teljes69 / kombo46
// vizsla hvi lp
const hvilpbalance3 = getBalanceNumber(useFarm7(getHVIAddress()))
const vizslalpbalance5 = getBalanceNumber(useFarm7(getCakeAddress()))
const kombo13 = useTotalSupplyFarm7()
const kombo14 = kombo13 ? getBalanceNumber(kombo13) : 0
const teljes19 = pricehvi * hvilpbalance3
const teljes20 = priceCake * vizslalpbalance5
const teljes21 = teljes19 + teljes20
const minden3 = teljes21 / kombo14
// lgc hvi lp 
const lgclp3 = getBalanceNumber(useFarm25(getlgcAddress()))
const hvilp4 = getBalanceNumber(useFarm25(getHVIAddress()))
const kombo49 = useTotalSupplyFarm25()
const kombo50 = kombo49 ? getBalanceNumber(kombo49) : 0
const teljes73 = priceLGC * lgclp3
const teljes74 = pricehvi * hvilp4
const teljes75 = teljes73 + teljes74
const minden4 = teljes75 / kombo50

  const fetchPendingRewards = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { pid: 0, setter: setNotAutoVizslaSwapBalance, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 20, setter: setvizslaswapbusdlp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 8, setter: setvizslaswapbusdlppancakeswap, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 1, setter: setvizslaswapbnblppanacakeswap, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 18, setter: setvizslaswapbnblpapeswap, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 3, setter: sethvibnblppancakeswap, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 49, setter: sethvibnblpapeswap, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 5, setter: sethvibusdlp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 28, setter: setbvcvizslaswaplp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 29, setter: setbabyvizslavizslaswaplp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 6, setter: setbabyvizslabnblp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 36, setter: sethvivizslaswaplp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' },
          { pid: 50, setter: setbethustlervizslaswaplp, address: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1' }
        ].map(async ({ pid, setter, address }) => {
          const contract = new ethers.Contract(address, STAKING_CONTRACT_ABI_WITH_PID, provider);
          const pendingReward = await contract.pendingCake(pid, account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);
  useEffect(() => {
    fetchPendingRewards();
  }, [account, fetchPendingRewards]);

  const fetchPendingRewardsLGC = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolLGC, address: '0x74339CB10Ff9E68918d2e4E23a4597e1046e89cA' }, 
          { setter: setpoolLGC2, address: '0x4fC86dC3E0B03971B4d08990843c754055C8c3D9' }, 
          { setter: setpoolLGC3, address: '0xC79EDA00d5EC26320693eaEE7b09987457CAeB50' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsLGC();
  }, [account, fetchPendingRewardsLGC]);

  const fetchPendingRewardsBabyvizsla = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolBabyvizsla, address: '0x646086C73D560B7a4581F6229738c88733C92e4B' }, 
          { setter: setpoolBabyvizsla2, address: '0x6688B8f6D2c0aEdeADf8c84d2aCDC87C2E1f02A9' }, 
          { setter: setpoolBabyvizsla3, address: '0x0340fdb81618874936535CA5276294fB84A719Ee' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsBabyvizsla();
  }, [account, fetchPendingRewardsBabyvizsla]);

  const fetchPendingRewardshvi = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolhvi, address: '0xe78Bf25A181A9b60Aef08a9F5e2079CB745234cb' }, 
          { setter: setpoolhvi2, address: '0x44c44e691e523378fec15Ba101AE0618fCA21cC4' }, 
          { setter: setpoolhvi3, address: '0x2BCB14C24F95E1D6978e1d9dcA5A1a01F4a68D88' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 9)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardshvi();
  }, [account, fetchPendingRewardshvi]);

  const fetchPendingRewardsvizslaswap = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolvizsla, address: '0x215BeF6E6dDF19ACE123610EC8b4B49E9d8A68BA' }, 
          { setter: setpoolvizsla2, address: '0xD24bffa1a9e3632A10CF07037aCf34bFe468Ff30' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsvizslaswap();
  }, [account, fetchPendingRewardsvizslaswap]);

  const fetchPendingRewardsbeth = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolbeth, address: '0xD97462E7CD68C5e408cAf745F43C06Bc02bd4BC4' }, 
          { setter: setpoolbeth2, address: '0x3804FcF2F09163a3459780298F1Cc6d7ee44165D' }, 
          { setter: setpoolbeth3, address: '0x7E7E1eD77ed403488d631758a83fcCd86a0aa787' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsbeth();
  }, [account, fetchPendingRewardsbeth]);

  const fetchPendingRewardslamea = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoollamea, address: '0x7027A5F70a93fDB1A6488f49db6f3e543E4881B7' }, 
          { setter: setpoollamea2, address: '0x495Cd5Fc0dF972A028AD50c7be23d2350702B228' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardslamea();
  }, [account, fetchPendingRewardslamea]);

  const fetchPendingRewardsetc = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpooletc, address: '0x78D1d79449da238bA76FB47CAff92De04C468622' }, 
          { setter: setpooletc2, address: '0xd4B328Ee8a6801ac9e93175C6dB55b70B08aef5E' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsetc();
  }, [account, fetchPendingRewardsetc]);

  const fetchPendingRewardsshib = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolshib, address: '0xebcF793c2cF3aeD607Fd64e71ac6BEdAAf7F915A' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsshib();
  }, [account, fetchPendingRewardsshib]);


  const fetchPendingRewardsltc = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolltc, address: '0xf142BD28CaCd766c24201bD2FF4513CEc032e882' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardsltc();
  }, [account, fetchPendingRewardsltc]);

  const fetchPendingRewardseth = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpooleth, address: '0xa05E95D44d746FC833041fCb62103A04850e1851' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardseth();
  }, [account, fetchPendingRewardseth]);

  const fetchPendingRewardslp1 = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolvizslaswaplamea, address: '0x47cE6b2Ec96D1576Da6D1B714C6ffd455E31132a' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardslp1();
  }, [account, fetchPendingRewardslp1]);

  const fetchPendingRewardslp2 = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoollgchvi, address: '0x1F125E10C83024D40B50077D76Cc119f63eF967A' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardslp2();
  }, [account, fetchPendingRewardslp2]);

  const fetchPendingRewardslp3 = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolvizslaswaplgc, address: '0x37031eD3a1adf0C4C72d3EB6832fDc3D1af42d2a' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardslp3();
  }, [account, fetchPendingRewardslp3]);

  const fetchPendingRewardslp4 = useCallback(async () => {
    if (account) {
      try {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);

        // Fetch pending rewards
        const pendingRewardsPromises = [
          { setter: setpoolvizslaswaphvi, address: '0x96e41f4e0F885121756b84fe966C39ED92047968' }, 
        ].map(async ({setter, address }) => {
          const contract = new ethers.Contract(address, POOL_ABI, provider);
          const pendingReward = await contract.pendingReward(account);
          setter(parseFloat(ethers.utils.formatUnits(pendingReward, 18)));
        });

        await Promise.all(pendingRewardsPromises);
      } catch (error) {
        console.error("Error fetching pending rewards:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    fetchPendingRewardslp4();
  }, [account, fetchPendingRewardslp4]);

  const vizslaSwapTotalBalance = notAutoVizslaSwapBalance + vizslaswapbusdlpBalance + vizslaswapbusdlppancakeswapBalance + vizslaswapbnblppancakeswapBalance + vizslaswapbnblpapeswapBalance + hvibnblppancakeswapBalance + hvibnblpapeswapBalance + hvibusdlpBalance + bvcvizslaswaplpBalance + babyvizslavizslaswaplpBalance + babyvizslabnblpBalance + hvivizslaswaplpBalance + bethustlersvizslaswaplpBalance;
  const lgcTotalBalance = poolLGCBalance + poolLGCBalance2 + poolLGCBalance3;
  const BabyvizslaTotalBalance = poolBabyvizslaBalance + poolBabyvizslaBalance2 + poolBabyvizslaBalance3;
  const hvitotalBalance = poolhviBalance + poolhviBalance2 + poolhviBalance3;
  const vizslaTotalBalance = poolvizslaBalance + poolvizslaBalance2;
  const bethTotalBalance = poolbethBalance + poolbethBalance2 + poolbethBalance3;
  const lameaTotalBalance = poollameaBalance + poollameaBalance2;
  const etcTotalBalance = pooletcBalance + pooletcBalance2;

  useEffect(() => {
    const valuesObj = {
      VIZSLASWAP: vizslaSwapTotalBalance * priceCake,
      LGC: lgcTotalBalance * priceLGC,
      BABYVIZSLA: BabyvizslaTotalBalance * priceBabyvizsla,
      HVI: hvitotalBalance * pricehvi,
      VIZSLASWAPPOOLS: vizslaTotalBalance * priceCake,
      BETHUSTLERS: bethTotalBalance * pricebeth,
      LAMEA: lameaTotalBalance * pricelamea,
      ETC: etcTotalBalance * priceetc,
      SHIB: poolshibBalance * priceshib,
      LTC: poolltcBalance * priceltc,
      ETH: poolethBalance * priceeth,
      VIZSLASWAPLAMEALP: poolvizslaswaplameaBalance * minden1,
      HVILGCLP: poollgchviBalance * minden4,
      VIZSLASWAPHVILP: poolvizslaswaphvi * minden3,
      VIZSLASWAPLGCLP: poolvizslaswaplgcBalance * minden2
    };
    setValues(valuesObj);
  }, [vizslaSwapTotalBalance, priceCake, lgcTotalBalance, priceLGC, BabyvizslaTotalBalance, priceBabyvizsla, hvitotalBalance, pricehvi, vizslaTotalBalance, bethTotalBalance, pricebeth, lameaTotalBalance, pricelamea, etcTotalBalance, priceetc, poolshibBalance, priceshib, poolltcBalance, priceltc, poolethBalance, priceeth, poolvizslaswaplameaBalance, minden1, poollgchviBalance, minden4, poolvizslaswaphvi, minden3, poolvizslaswaplgcBalance, minden2]);

  const sortedTokens = Object.keys(values)
    .filter(token => values[token] > 0) // Only include tokens with a value greater than 0
    .sort((a, b) => values[b] - values[a]); // Sort by USD value descending

  const totalValue = Object.values(values).reduce((acc, value) => acc + value, 0);

  return (
    <Container>
      <FarmsPoolsRowLoader />
      <Title>Pending Rewards Checker</Title>
      {account ? (
        <StyledButton onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? 'Hide Details ▲' : 'Show Details ▼'}
          <Arrow direction={showDetails ? 'up' : 'down'} />
        </StyledButton>
      ) : (
        <ConnectWalletButton />
      )}

      {showDetails && (
        <>
          <TotalValue>Total Pending Rewards: ${totalValue.toFixed(2)}</TotalValue>
          <TokenList>
            {sortedTokens.map(token => (
              <TokenCard
                key={token}
                isSelected={selectedToken === token}
                onClick={() => setSelectedToken(selectedToken === token ? null : token)}
              >
                <TokenImage src={getTokenImageSrc(TOKEN_ADDRESSES[token])} alt={token} />
                <TokenDetails>
                  <TokenLabel>{token}</TokenLabel>
                  <TokenAddress>{TOKEN_ADDRESSES[token]}</TokenAddress>
                  <TokenValue>
                    {token === 'VIZSLASWAP' ? vizslaSwapTotalBalance.toFixed(2) 
                   : token === 'LGC' ? lgcTotalBalance.toFixed(2) 
                   : token === 'HVI' ? hvitotalBalance.toFixed(2) 
                   : token === 'VIZSLASWAPPOOLS' ? vizslaTotalBalance.toFixed(2) 
                   : token === 'BETHUSTLERS' ? bethTotalBalance.toFixed(2) 
                   : token === 'LAMEA' ? lameaTotalBalance.toFixed(2)
                   : token === 'ETC' ? etcTotalBalance.toFixed(2)  
                   : token === 'SHIB' ? poolshibBalance.toFixed(2)  
                   : token === 'LTC' ? poolltcBalance.toFixed(2)  
                   : token === 'ETH' ? poolethBalance.toFixed(2)  
                   : token === 'VIZSLASWAPLAMEALP' ? poolvizslaswaplameaBalance.toFixed(2)  
                   : token === 'HVILGCLP' ? poollgchviBalance.toFixed(2)  
                   : token === 'VIZSLASWAPLGCLP' ? poolvizslaswaplgcBalance.toFixed(2)  
                   : token === 'VIZSLASWAPHVILP' ? poolvizslaswaphvi.toFixed(2)  
                   : BabyvizslaTotalBalance.toFixed(2)} 
                    {' '} 
                    (${values[token] ? values[token].toFixed(2) : '0.00'})
                  </TokenValue>
                </TokenDetails>
              </TokenCard>
            ))}
          </TokenList>
        </>
      )}
    </Container>
  );
};

export default User2;
